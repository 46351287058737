import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import Select from 'react-select';
import ImageCropper from '~/components/input/ImageCropper/ImageCropper';
import { City, FormAssociate, Image, ModalAssociateProps, SelectControlledOption } from '~/types';
import { closeModal, openModal } from '~/utils/util';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import InputMaskControlled from '~/components/input/InputMaskControlled';
import BasicInput from '~/components/input/BasicInput';
import SelectControlled from '~/components/input/SelectControlled';
import BasicTextarea from '~/components/input/BasicTextarea';
import MaskPhoneControlled from '~/components/input/MaskPhoneControlled';
import MaskCEPControlled from '~/components/input/MaskCEPControlled';
import MoneyMaskControlled from '~/components/input/MaskMoneyControlled';
import { getCitiesByState, searchByZipCode } from '~/service/AddressService';
import { saveAssociate } from '~/service/AssociateService';
import { baseURL } from '~/config/api';
import { showAlertDanger, showAlertSuccess, showAlertWarning } from '~/components/notification';
import { AuthContext } from '~/context/AuthContext';

const statusVPMIXOptions = [
  {
    value: 'Ativo',
    label: 'Ativo',
  }, 
  {
    value: 'Bloqueado',
    label: 'Bloqueado',
  },
  {
    value: 'Inativo',
    label: 'Inativo',
  },
];

const associateSchema: yup.SchemaOf<FormAssociate> = yup.object().shape({
  emp_cpfcnpj: yup.string().required(),
  emp_razaosocial: yup.string().required(),
  emp_nomefantasia: yup.string().required(),
  iti_codigo: yup.number().required(),
  seg_codigo: yup.number().required(),
  emp_descritivo: yup.string().required(),
  emp_contatoadmin: yup.string().required(),
  emp_emailadmin: yup.string().email().required(),
  emp_telefoneadmin: yup
    .string()
    .matches(/^[0-9]{11}$/)
    .required(),

  len_cep: yup
    .string()
    .matches(/^[0-9]{8}$/)
    .required(),
  len_estado: yup.string().required(),
  len_cidade: yup.string().required(),
  len_logradouro: yup.string().required(),
  len_numero: yup.number(),
  len_complemento: yup.string(),
  len_bairro: yup.string().required(),

  emp_statusvpmix: yup.string(),
  emp_taxavpmix: yup.number(),
});

const ViewModalAssociate: React.FunctionComponent<ModalAssociateProps> = ({
  associate,
  segments,
  states,
  statusOptions,
  onSubmit,
}) => {

  const [imageToCrop, setImageToCrop] = useState<Image | undefined>(undefined);
  const [image, setImage] = useState<Image | undefined>(undefined);
  const [isAwaitLoadingZipCode, setIsAwaitLoadingZipCode] = useState(false);
  const [cities, setCities] = useState<City[]>([]);
  const [isLoadingCities, setIsLoadingCities] = useState(false);
  const [isAwaitResponse, setIsAwaitResponse] = useState(false);

  const { watch, control, handleSubmit, errors, register, getValues, setValue, reset } = useForm<FormAssociate>({
    resolver: yupResolver(associateSchema),
  });

  useEffect(() => {
    if (associate === undefined) {
      reset();
      setImage(undefined);
    } else {
      setImage({
        isNew: false,
        preview: `${baseURL}/web/uploads/logoLoja/${associate.EMP_LOGOTIPO}`,
        name: associate.EMP_LOGOTIPO,
        label: associate.EMP_LOGOTIPO,
      });

      fetchCitiesByState(associate.LEN_ESTADO);
    }
  }, [associate]);

  const renderDragMessage = (isDragActive: boolean, isDragReject: boolean) => {
    if (!isDragActive)
      return (
        <>
          <p className="dropContainterMessageDefault">Arraste a logo do associado para cá...</p>
          <small className="dropContainterMessageDefault" style={{ color: '#9a9a9a' }}>
            (apenas .png)
          </small>
        </>
      );
    if (isDragReject) return <p className="dropContainterMessageDanger">Arquivo não suportado...</p>;

    return <p className="dropContainterMessageSuccess">Solte a imagem aqui...</p>;
  };

  const onCropSuccess = (image: Image) => {
    setImage(image);
    setImageToCrop(undefined);
    closeModal('ImageCropper');
    setTimeout(() => {
      document.body.classList.add('modal-open');
    }, 500);
  };

  const handleSearchByZipCode = async (zipCode: string) => {
    if (zipCode.trim().length === 8) {
      setIsAwaitLoadingZipCode(true);
      setIsLoadingCities(true);
      const { success, payload } = await searchByZipCode(zipCode);
      setIsAwaitLoadingZipCode(false);
      setIsLoadingCities(false);

      if (success && payload.ceps.length > 0) {
        setCities(payload.cidades);
        const { UF, CIDADE, LOGRADOURO, BAIRRO } = payload.ceps[0];
        setValue('len_estado', UF);
        setValue('len_cidade', CIDADE);
        setValue('len_logradouro', LOGRADOURO);
        setValue('len_bairro', BAIRRO);
      }
    }
  };

  const fetchCitiesByState = async (uf: string) => {
    if (uf !== '') {
      setCities([]);
      setIsLoadingCities(true);
      const { success, payload } = await getCitiesByState(uf);
      setIsLoadingCities(false);

      if (success) setCities(payload.cidades);
    }
  };

  // const handleOnSubmit = async (formAssociate: FormAssociate) => {
  //   setIsAwaitResponse(true);
  //   const { success, payload, error, type, message } = await saveAssociate({
  //     ...formAssociate,
  //     emp_codigo: associate?.EMP_CODIGO,
  //     emp_tipo: 'A',
  //     emp_logotipo: associate?.EMP_LOGOTIPO,
  //     newFile: image?.file,

  //     usu_codigo: usuario.USU_CODIGO,
  //     current_emp_codigo: company.EMP_CODIGO,
  //     current_emp_codmatriz: company.EMP_CODMATRIZ,
  //     ...(associate ? { len_codigo: associate.LEN_CODIGO } : undefined),
  //   });
  //   setIsAwaitResponse(false);

  //   if (success) {
  //     showAlertSuccess({ message: message });
  //     closeModal('ModalFormAssociado');
  //     await onSubmit();
  //   } else if (type === 'saveFileError') {
  //     showAlertWarning({
  //       message: (
  //         <>
  //           O associado foi salvo, mas ocorreu um problema ao salvar a logo.
  //           <br />
  //           {message}
  //         </>
  //       ),
  //       timeout: 10,
  //     });
  //     await onSubmit();
  //     autoSet(undefined);
  //     autoSet(payload.empresa);
  //   } else if (type === 'cnpjDuplicado' || type === 'loginDuplicado') {
  //     showAlertDanger({ message: message });
  //   } else {
  //     showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
  //   }
  // };

  const handleOnSubmit = async (formAssociate: FormAssociate) => {
    setIsAwaitResponse(true);
    await onSubmit({ ...formAssociate, image: image });
    setIsAwaitResponse(false);
  };

  return (
    <>
      <div
        className="modal fade"
        id="ModalFormAssociado"
        role="dialog"
        aria-labelledby="usuarioLabel"
        aria-hidden="true"
        tabIndex={-1}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="card stacked-form">
                    <div className="card-header ">
                      <h4 className="card-title">Associado</h4>
                    </div>
                    <div className="card-body ">
                      <form
                        key={'ModalFormAssociadoId' + associate?.EMP_CODIGO}
                        onSubmit={handleSubmit(handleOnSubmit, console.log)}
                        id="ModalFormAssociadoId"
                      >
                        <div className="form-group">
                          <Dropzone
                            accept={['image/png', 'image/jpeg']}
                            multiple={false}
                            onDropAccepted={(file) => {
                              openModal('ImageCropper');
                              setImageToCrop({
                                preview: URL.createObjectURL(file[0]),
                                file: file[0],
                                isNew: true,
                                name:
                                  moment(new Date()).format('YYYY-MM-DD-HH-mm-ss-SSS') +
                                  '.' +
                                  file[0].name.split('.').pop(),
                                label: file[0].name,
                              });
                            }}
                            onDropRejected={(files) => {
                              if (files[0].errors.some((error) => error.code === 'file-invalid-type'))
                                showAlertWarning({ message: 'Só são aceitas imagens no formato .pgn' });
                            }}
                          >
                            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                              <div
                                {...getRootProps()}
                                className="dropContainter"
                                style={{ borderColor: isDragReject ? '#e57878' : isDragActive ? '#78e5d5' : '' }}
                              >
                                <input {...getInputProps()} />
                                {image ? (
                                  <img src={image.preview} alt="logomarca" style={{ width: '100%', padding: '5px' }} />
                                ) : (
                                  renderDragMessage(isDragActive, isDragReject)
                                )}
                              </div>
                            )}
                          </Dropzone>
                        </div>

                        <InputMaskControlled
                          isRequired
                          control={control}
                          defaultValue={associate?.EMP_CPFCNPJ || ''}
                          hasError={!!errors.emp_cpfcnpj}
                          name="emp_cpfcnpj"
                          label="CPF ou CNPJ"
                          placeholder={
                            watch('emp_cpfcnpj', '').substr(8, 3) === '000' ? '__. ___. ___/____-__' : '___.___.___-__'
                          }
                          format={
                            watch('emp_cpfcnpj', '').substr(8, 3) === '000' ? '##. ###. ###/####-##' : '###.###.###-##'
                          }
                          mask="_"
                          isNumericString
                        />

                        <BasicInput
                          isRequired
                          control={control}
                          type="text"
                          defaultValue={associate?.EMP_RAZAOSOCIAL || ''}
                          hasError={!!errors.emp_razaosocial}
                          name="emp_razaosocial"
                          label="Razão Social"
                        />

                        <BasicInput
                          isRequired
                          control={control}
                          type="text"
                          defaultValue={associate?.EMP_NOMEFANTASIA || ''}
                          hasError={!!errors.emp_nomefantasia}
                          name="emp_nomefantasia"
                          label="Nome Fantasia"
                        />

                        <div className="form-group">
                          <label>Cód. Associado</label>
                          <input disabled type="text" className="form-control" value={associate?.EMP_CODIGOFRANQUIA} />
                        </div>

                        <SelectControlled
                          isRequired
                          control={control}
                          defaultValue={associate?.ITI_CODIGO || '7'}
                          options={statusOptions || []}
                          hasError={!!errors.iti_codigo}
                          name="iti_codigo"
                          label="Situação"
                        />

                        <SelectControlled
                          isRequired
                          control={control}
                          defaultValue={associate?.SEG_CODIGO || ''}
                          options={
                            segments?.map((segment) => ({ value: segment.SEG_CODIGO, label: segment.SEG_DESCRICAO })) ||
                            []
                          }
                          hasError={!!errors.seg_codigo}
                          name="seg_codigo"
                          label="Segmento"
                        />

                        <BasicTextarea
                          isRequired
                          ref={register}
                          defaultValue={associate?.EMP_DESCRITIVO || ''}
                          hasError={!!errors.emp_descritivo}
                          name="emp_descritivo"
                          label="Descritivo do Associado"
                          height="60px"
                        />

                        <BasicInput
                          isRequired
                          control={control}
                          type="text"
                          defaultValue={associate?.EMP_CONTATOADMIN || ''}
                          hasError={!!errors.emp_contatoadmin}
                          name="emp_contatoadmin"
                          label="Contato Gestor"
                        />

                        <BasicInput
                          isRequired
                          control={control}
                          type="email"
                          defaultValue={associate?.EMP_EMAILADMIN || ''}
                          hasError={!!errors.emp_emailadmin}
                          name="emp_emailadmin"
                          label="Email Gestor"
                        />

                        <MaskPhoneControlled
                          control={control}
                          isRequired
                          defaultValue={associate?.EMP_TELEFONEADMIN || ''}
                          hasError={!!errors.emp_telefoneadmin}
                          name="emp_telefoneadmin"
                          label="Telefone Gestor"
                        />

                        <div className="form-group">
                          <label>Comissão Compra (%)</label>
                          <input
                            disabled
                            type="text"
                            placeholder="%"
                            className="form-control"
                            value={associate?.EMP_COMISSAOASSDEBPER}
                          />
                        </div>
                        <div className="form-group">
                          <label>Comissão Compra (R$)</label>
                          <input
                            disabled
                            type="text"
                            placeholder="R$"
                            className="form-control"
                            value={associate?.EMP_COMISSAOASSDEBVAL}
                          />
                        </div>
                        <div className="form-group">
                          <label>Comissão Venda (%)</label>
                          <input
                            disabled
                            type="text"
                            placeholder="%"
                            className="form-control"
                            value={associate?.EMP_COMISSAOASSCREDPER}
                          />
                        </div>
                        <div className="form-group">
                          <label>Comissão Venda (R$)</label>
                          <input
                            disabled
                            type="text"
                            placeholder="R$"
                            className="form-control"
                            value={associate?.EMP_COMISSAOASSCREDVAL}
                          />
                        </div>

                        <hr />
                        <h3>Endereço</h3>

                        <MaskCEPControlled
                          control={control}
                          isRequired
                          defaultValue={associate?.LEN_CEP || ''}
                          hasError={!!errors.len_cep}
                          name="len_cep"
                          label="CEP"
                          isLoading={isAwaitLoadingZipCode}
                          onBlur={() => {
                            handleSearchByZipCode(getValues('len_cep'));
                          }}
                        />

                        <SelectControlled
                          isRequired
                          control={control}
                          defaultValue={associate?.LEN_ESTADO || ''}
                          options={states?.map((state) => ({ value: state.UF, label: state.ESTADO })) || []}
                          hasError={!!errors.len_estado}
                          name="len_estado"
                          label="Estado"
                          onBlur={() => fetchCitiesByState(getValues('len_estado'))}
                        />

                        <SelectControlled
                          isRequired
                          control={control}
                          defaultValue={associate?.LEN_CIDADE || ''}
                          options={cities.map((city) => ({ value: city.CIDADE, label: city.CIDADE }))}
                          hasError={!!errors.len_cidade}
                          name="len_cidade"
                          label="Cidade"
                          isLoading={isAwaitLoadingZipCode || isLoadingCities}
                        />

                        <BasicInput
                          isRequired
                          control={control}
                          type="text"
                          defaultValue={associate?.LEN_LOGRADOURO || ''}
                          hasError={!!errors.len_logradouro}
                          name="len_logradouro"
                          label="Logradouro"
                        />

                        <BasicInput
                          isRequired
                          control={control}
                          type="number"
                          defaultValue={associate?.LEN_NUMERO || 'undefined'}
                          hasError={!!errors.len_numero}
                          name="len_numero"
                          label="Número"
                        />

                        <BasicInput
                          control={control}
                          type="text"
                          defaultValue={associate?.LEN_COMPLEMENTO || ''}
                          hasError={!!errors.len_complemento}
                          name="len_complemento"
                          label="Complemento"
                        />

                        <BasicInput
                          isRequired
                          control={control}
                          type="text"
                          defaultValue={associate?.LEN_BAIRRO || ''}
                          hasError={!!errors.len_bairro}
                          name="len_bairro"
                          label="Bairro"
                        />

                        <hr />
                        <h3>Configurações VPMIX</h3>

                        <SelectControlled
                          control={control}
                          defaultValue={associate?.EMP_STATUSVPMIX || 'Inativo'}
                          options={statusVPMIXOptions}
                          hasError={!!errors.emp_statusvpmix}
                          name="emp_statusvpmix"
                          label="Status"
                        />

                        <div className="form-group">
                          <label>Última alteração</label>
                          <input
                            type="date"
                            max="2999-12-31"
                            disabled
                            placeholder=""
                            className="form-control"
                            value={associate?.EMP_DATAALTERACAOSTATUSVPMIX}
                          />
                        </div>

                        <div className="form-group">
                          <label>Última ativação</label>
                          <input
                            type="date"
                            max="2999-12-31"
                            disabled
                            placeholder=""
                            className="form-control"
                            value={associate?.EMP_DATAATIVACAOVPMIX}
                          />
                        </div>

                        <MoneyMaskControlled
                          control={control}
                          defaultValue={Number(associate?.EMP_TAXAVPMIX) || undefined}
                          hasError={!!errors.emp_taxavpmix}
                          name="emp_taxavpmix"
                          label="Taxa de inclusão (R$)"
                          prefix="R$"
                        />

                        <div className="form-group">
                          <label>Comissão VPMIX Compra (%)</label>
                          <input
                            disabled
                            type="text"
                            placeholder="%"
                            className="form-control"
                            value={associate?.EMP_COMISSAOASSDEBPERVPMIX}
                          />
                        </div>
                        <div className="form-group">
                          <label>Comissão VPMIX Compra (R$)</label>
                          <input
                            disabled
                            type="text"
                            placeholder="R$"
                            className="form-control"
                            value={associate?.EMP_COMISSAOASSDEBVALVPMIX}
                          />
                        </div>
                        <div className="form-group">
                          <label>Comissão VPMIX Venda (%)</label>
                          <input
                            disabled
                            type="text"
                            placeholder="%"
                            className="form-control"
                            value={associate?.EMP_COMISSAOASSCREDPERVPMIX}
                          />
                        </div>
                        <div className="form-group">
                          <label>Comissão VPMIX Venda (R$)</label>
                          <input
                            disabled
                            type="text"
                            placeholder="R$"
                            className="form-control"
                            value={associate?.EMP_COMISSAOASSCREDVALVPMIX}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
                <i className="fa fa-arrow-left"></i> Voltar
              </button>
              <button
                type="submit"
                className="btn btn-success btn-wd"
                form="ModalFormAssociadoId"
                disabled={isAwaitResponse}
              >
                {isAwaitResponse ? (
                  <>
                    <i className="fas fa-spinner rotating" /> Processando...
                  </>
                ) : (
                  <>
                    <i className="fa fa-save" /> Salvar
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ImageCropper image={imageToCrop} onConfirm={onCropSuccess} />
    </>
  );
};

export default ViewModalAssociate;
