import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="container d-flex flex-row justify-content-center">
        <nav className="float-center">
          <p className="copyright text-center">
            ©
            <a href="http://www.lakstech.com.br" target="_blank">
              Laks Tecnologia
            </a>
            , faça algo ótimo
          </p>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
