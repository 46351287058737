import api from '~/config/api';
import { baseURL } from '~/config/api';
import { encode } from 'querystring';
import { downloadDocuments } from '~/utils/util';

export const getFilterData = async () => {
  try {
    const { data } = await api.get('/api/associados/dados-filtro');
    return data;
  } catch (error) {
    return error;
  }
};

export const getAssociates = async ({ seg_codigo = null, len_cidibge = null, emp_codigo = null }) => {
  try {
    const { data } = await api.post('/api/associados/carregar-associados', {
      seg_codigo,
      len_cidibge,
      emp_codigo,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const getAssociatesCredit = async ({ emp_codmatriz }) => {
  try {
    const { data } = await api.post('/api/associados/carregar-associados-credito', {
      emp_codmatriz,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const getAssociatesFranchise = async ({ emp_codigo, iti_codigo }) => {
  try {
    const { data } = await api.post('/api/associados/carregar-associados-franquia', {
      emp_codigo,
      iti_codigo,
    });
    return data;
  } catch (error) {
    return { success: false, error };
  }
};

export const saveAssociate = async (associate) => {
  try {
    const { data } = await api.post('/api/associados/salvar', serializeAssociateToFormData(associate));
    return data;
  } catch (error) {
    return { success: false, error };
  }
};

const serializeAssociateToFormData = (associate) => {
  const associateFormData = new FormData();
  const { newFile, ...object } = associate;

  associateFormData.append('object', JSON.stringify(object));

  if (newFile) associateFormData.append('newFile', newFile);

  return associateFormData;
};

export const exportAssociatesToXLS = ({ emp_codigo }) => {
  const url = `${baseURL}/api/associados/exportar?${encode({
    e: emp_codigo,
  })}`;

  downloadDocuments(url, `Associados.xls`, false);
};

export const getAssociateCreditRecords = async ({ emp_codigo }) => {
  try {
    const { data } = await api.post('/api/associados/carregar-associado-credito-registros', {
      emp_codigo,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const saveAssociateCreditRecord = async ({
  acr_observacao,
  acr_valor,
  emp_codigo,
  usu_codigo,
  acr_codigo,
  requester_emp_codigo,
}) => {
  try {
    const { data } = await api.post('/api/associados/salvar-credito-registro', {
      acr_observacao,
      acr_valor,
      emp_codigo,
      usu_codigo,
      acr_codigo,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const transferAssociates = async ({ associates, franchiseId }) => {
  try {
    const { data } = await api.post('/api/associados/transferir-associados', {
      associados: associates,
      franquia: franchiseId,
    });
    return data;
  } catch (error) {
    return error;
  }
};
