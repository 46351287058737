import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { closeModal, correctNumberWithComma, formatNumberToVPS } from '~/utils/util';
import SelectControlled from '~/components/input/SelectControlled';
import moment from 'moment';
import Datetime from 'react-datetime';
import ReactSelect from 'react-select';
import {
  AssociateCreditHistory,
  AssociateWithCredit,
  FormCreditRecord,
  FormCreditRecordAux,
  SelectControlledOption,
} from '~/types';
import TableWithLinks from '~/components/table/TableWithLinks';
import MaskMoneyControlled from '~/components/input/MaskMoneyControlled';
import BasicTextarea from '~/components/input/BasicTextarea';
import ButtonDefault from '~/components/button/ButtonDefault';
import { AuthContext } from '~/context/AuthContext';
import { showAlertDanger } from '~/components/notification';
import CheckBoxControlled from '~/components/input/CheckBoxControlled';

type AssociateCreditHistoryAux = AssociateCreditHistory & {
  valueFormatted: string;
  creatorName: string;
  observations: string;
};

interface ModalCreditRecordsProps {
  isLoading: boolean;
  associate: AssociateWithCredit | undefined;
  creditRecords: AssociateCreditHistory[];
  onSubmit: (formData: FormCreditRecordAux) => Promise<any>;
  isAwaitResponse: boolean;
}

const ModalCreditRecords: React.FunctionComponent<ModalCreditRecordsProps> = ({
  isLoading,
  associate,
  creditRecords,
  onSubmit,
  isAwaitResponse,
}) => {
  const { user, company } = useContext(AuthContext);

  const [selectedRecord, setSelectedRecord] = useState<AssociateCreditHistoryAux | undefined>(undefined);

  useEffect(() => {
    setSelectedRecord(undefined);
  }, [creditRecords]);

  const FormCreditRecordSchema: yup.SchemaOf<FormCreditRecord> = yup.object().shape({
    acr_valor: yup.number().required(),
    acr_observacao: yup.string(),
    subtract: yup.boolean(),
  });

  const { control, register, handleSubmit, errors, reset } = useForm<FormCreditRecord>({
    resolver: yupResolver(FormCreditRecordSchema),
  });

  const handleOnSubmit = async ({ acr_valor, acr_observacao, subtract }: FormCreditRecord) => {
    if (associate == undefined)
      showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, recarregue a página e tente novamente.' });
    else {
      const result =
        selectedRecord === undefined
          ? await onSubmit({
              acr_valor: subtract ? acr_valor * -1 : acr_valor,
              acr_observacao,
              emp_codigo: +associate.EMP_CODIGO,
              usu_codigo: +user.USU_CODIGO,
            })
          : await onSubmit({
              acr_observacao,
              acr_codigo: +selectedRecord.ACR_CODIGO,
              emp_codigo: +associate.EMP_CODIGO,
            });

      if (result) {
        setSelectedRecord(undefined);
        reset();
      }
    }
  };

  return (
    <div
      className="modal fade"
      id="ModalCreditRecords"
      role="dialog"
      aria-labelledby="Modal-Filter"
      aria-hidden="true"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card stacked-form">
                  <div className="card-header ">
                    <h4 className="card-title">Histórico de Registros</h4>
                    <span>{associate?.EMP_NOMEFANTASIA}</span>
                  </div>
                  <div className="card-body table-full-width" style={{ paddingTop: 0 }}>
                    <form onSubmit={handleSubmit(handleOnSubmit, console.log)} id="ModalCreditRecordsFormId">
                      <div className="form-group" key={`ModalCreditRecordsFormId_${selectedRecord?.ACR_CODIGO}`}>
                        <MaskMoneyControlled
                          disabled={selectedRecord !== undefined}
                          control={control}
                          isRequired
                          defaultValue={
                            selectedRecord != undefined ? correctNumberWithComma(selectedRecord.ACR_VALOR) : undefined
                          }
                          hasError={!!errors.acr_valor}
                          name="acr_valor"
                          label="Adicionar/Remover Crédito"
                          prefix="SC$"
                          checkbox={
                            <CheckBoxControlled
                              isDisabled={selectedRecord !== undefined}
                              control={control}
                              defaultValue={false}
                              name="subtract"
                              label="Subtrair crédito"
                            />
                          }
                        />

                        <BasicTextarea
                          label="Observações"
                          hasError={!!errors.acr_observacao}
                          height="60px"
                          ref={register}
                          name="acr_observacao"
                          defaultValue={selectedRecord?.ACR_OBSERVACAO || ''}
                        />
                      </div>
                      <div className="d-flex justify-content-end">
                        <ButtonDefault
                          className={'btn btn-wd' + (selectedRecord === undefined ? ' btn-success' : ' btn-primary')}
                          isLoading={isAwaitResponse}
                          disabled={isAwaitResponse}
                        >
                          {selectedRecord === undefined ? (
                            <>
                              <i className="fas fa-plus"></i> Adicionar
                            </>
                          ) : (
                            <>
                              <i className="fa fa-save"></i> Alterar observações
                            </>
                          )}
                        </ButtonDefault>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <TableWithLinks
              isLoading={isLoading || isAwaitResponse}
              emptyTableMessage="Nenhum registro encontrado."
              onClickingRow={(record: AssociateCreditHistoryAux) =>
                setSelectedRecord((current) =>
                  current === undefined || current.ACR_CODIGO !== record.ACR_CODIGO ? record : undefined
                )
              }
              highlightRow={(record: AssociateCreditHistoryAux) => selectedRecord?.ACR_CODIGO == record.ACR_CODIGO}
              columns={[
                { field: 'ACR_DATA', label: 'Data do Registro', alignment: 'text-left', width: '150px' },
                { field: 'valueFormatted', label: 'Valor', alignment: 'text-center', width: '150px' },
                { field: 'creatorName', label: 'Adicionado por' },
                { field: 'observations', label: 'Observações' },
              ]}
              data={creditRecords.map(
                (creditRecord: AssociateCreditHistory): AssociateCreditHistoryAux => ({
                  ...creditRecord,
                  valueFormatted: formatNumberToVPS(correctNumberWithComma(creditRecord.ACR_VALOR)),
                  creatorName: creditRecord.CREATOR_NOME || '--',
                  observations: creditRecord.ACR_OBSERVACAO || '--',
                })
              )}
            />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
            <div>
              Total:
              <b>
                {formatNumberToVPS(
                  creditRecords.reduce((acc, creditRecord) => acc + correctNumberWithComma(creditRecord.ACR_VALOR), 0)
                )}
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCreditRecords;
