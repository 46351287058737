import React, { useContext } from 'react';
import SelectAsyncControlled from '~/components/input/SelectAsyncControlled';
import Modal from '~/components/modal/Modal';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { autoCompleteFinances } from '~/service/financeiro/FinanceService';
import { formatCNPJ, formatCPF } from '~/utils/util';
import { ExportAssociateStatementProps, FilterExportAssociateStatement } from '~/types';
import { autoCompleteAssociatePerFranchise } from '~/service/NegotiationService';
import { AuthContext } from '~/context/AuthContext';
import BasicInput from '~/components/input/BasicInput';
import moment from 'moment';
import SelectControlled from '~/components/input/SelectControlled';
import CheckBoxControlled from '~/components/input/CheckBoxControlled';

const FilterExportAssociateStatementSchema: yup.SchemaOf<FilterExportAssociateStatement> = yup.object().shape({
  searchAll: yup.boolean().required(),
  emp_codigo: yup
    .number()
    .when('searchAll', (searchAll: boolean, innerSchema: any) =>
      searchAll ? yup.number().transform((value) => (Number(value) ? value : undefined)) : yup.number().required()
    ),
  status: yup.mixed().required().oneOf(['S', 'N', 'T']),
  disableFilterDate: yup.string().oneOf(['S', 'N']).required(),
  dateStart: yup.string().when('disableFilterDate', { is: 'N', then: yup.string().required(), otherwise: yup.string() }),
  dateEnd: yup.string().when('disableFilterDate', { is: 'N', then: yup.string().required(), otherwise: yup.string() }),
  type: yup.mixed().oneOf(['PDF', 'XLS']).required(),
});

const ExportAssociateStatement: React.FunctionComponent<ExportAssociateStatementProps> = ({ onSubmit }) => {
  const { control, handleSubmit, watch, errors, setValue } = useForm<FilterExportAssociateStatement>({
    resolver: yupResolver(FilterExportAssociateStatementSchema),
    reValidateMode: 'onChange',
  });
  const { company } = useContext(AuthContext);

  const handleOnSubmit: SubmitHandler<FilterExportAssociateStatement> = (params: FilterExportAssociateStatement) => {
    onSubmit(params);
  };

  return (
    <Modal
      id="ModalExportAssociateStatement"
      size="sm"
      header={() => (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <h4 className="card-title">Lançamentos</h4>
        </div>
      )}
      body={() => (
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body " style={{ paddingTop: 0 }}>
                <form onSubmit={handleSubmit(handleOnSubmit, console.log)} id="FormExportAssociateStatementId">
                  <SelectAsyncControlled
                    isDisabled={watch('searchAll')}
                    control={control}
                    defaultValue={''}
                    hasError={!!errors.emp_codigo}
                    name="emp_codigo"
                    label="Nome fantasia"
                    placeholder="Pesquise por nome ou cpf"
                    loadOptions={async (value: string) => {
                      const { payload, error } = await autoCompleteAssociatePerFranchise({ value: value, emp_codigo: company.EMP_CODIGO });
                      if (!error) {
                        return payload.map(
                          (option: { EMP_CODIGO: string; EMP_CPFCNPJ: string; EMP_NOMEFANTASIA: string; EMP_RAZAOSOCIAL: string }) => ({
                            value: option.EMP_CODIGO,
                            label: `${option.EMP_RAZAOSOCIAL} (${
                              option.EMP_CPFCNPJ.length === 14 ? formatCNPJ(option.EMP_CPFCNPJ) : formatCPF(option.EMP_CPFCNPJ)
                            })`,
                          })
                        );
                      } else {
                        console.log(error);
                        return [];
                      }
                    }}
                    checkbox={
                      <CheckBoxControlled
                        control={control}
                        defaultValue={false}
                        name="searchAll"
                        label="Todos os associados"
                        onChangeAux={() => {
                          setValue('emp_codigo', undefined);
                          setValue('type', 'XLS');
                        }}
                      />
                    }
                  />

                  <SelectControlled
                    control={control}
                    defaultValue="PDF"
                    hasError={!!errors.disableFilterDate}
                    label="Tipo de relatório"
                    name="type"
                    options={[
                      { value: 'PDF', label: 'PDF', isDisabled: watch('searchAll') === true },
                      { value: 'XLS', label: 'XLS', isDisabled: false },
                    ]}
                  />

                  <SelectControlled
                    control={control}
                    defaultValue="T"
                    hasError={!!errors.status}
                    label="Status da comissão"
                    name="status"
                    options={[
                      { value: 'T', label: 'Todas' },
                      { value: 'S', label: 'Baixado' },
                      { value: 'N', label: 'Não baixado' },
                    ]}
                  />

                  <SelectControlled
                    control={control}
                    defaultValue="N"
                    hasError={!!errors.disableFilterDate}
                    label="Filtrar por data?"
                    name="disableFilterDate"
                    options={[
                      { value: 'S', label: 'Não' },
                      { value: 'N', label: 'Sim' },
                    ]}
                  />

                  {watch('disableFilterDate') === 'N' && (
                    <>
                      <BasicInput
                        control={control}
                        defaultValue={moment().clone().startOf('month').format('YYYY-MM-DD')}
                        hasError={!!errors.dateStart}
                        name="dateStart"
                        type="date"
                        label="Início"
                        max="2999-12-31"
                      />

                      <BasicInput
                        control={control}
                        defaultValue={moment().clone().endOf('month').format('YYYY-MM-DD')}
                        hasError={!!errors.dateEnd}
                        name="dateEnd"
                        type="date"
                        label="Fim"
                        max="2999-12-31"
                      />
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      footer={() => (
        <>
          <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
            <i className="fa fa-arrow-left"></i> Voltar
          </button>
          <button className="btn btn-default btn-wd" form="FormExportAssociateStatementId" type="submit">
            <i className="fas fa-file-export"></i> Exportar
          </button>
        </>
      )}
    />
  );
};

export default ExportAssociateStatement;
