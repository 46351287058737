import React, { useEffect, useState } from 'react';
import { Offer, TableOffersProps } from '~/types';
import ImagemFlutuante from '~/components/img/ImagemFlutuante';
import { baseURL } from '~/config/api';
import LoadingSpin from '~/components/loading/LoadingSpin';
import LoadingPointsSpin from '~/components/loading/LoadingPointsSpin';
import * as Util from '~/utils/util';
import { showAlertSuccess, showAlertWarning } from '~/components/notification';
import { OfferType } from '~/enum';

const defaultProps = {
  openOffer: false,
  enableShareLink: false,
};

const TableOffers: React.FunctionComponent<TableOffersProps> = ({
  offers,
  isLoading,
  onSelectOffer,
  enableShareLink,
}) => {
  const [sort, setSort] = useState({ column: 'none', order: '' });
  const [filteredOffers, setFilteredOffers] = useState([] as Array<Offer>);

  useEffect(() => {
    if (sort.column === 'none') {
      setFilteredOffers([
        ...offers.sort((a: Offer, b: Offer) => {
          const orderByDate = Util.formatAndCompareDate(a.OFE_PRAZORESGATE, b.OFE_PRAZORESGATE);
          const orderById = +a.OFE_CODIGO - +b.OFE_CODIGO;

          if (orderByDate !== 0) return orderByDate;
          else return orderById;
        }),
      ]);
    } else {
      setFilteredOffers([
        ...offers.sort((a, b) => {
          if (sort.column === 'OFE_TITULO' && sort.order === 'ASC')
            return Util.compareStrings(a.OFE_TITULO, b.OFE_TITULO);
          else if (sort.column === 'OFE_TITULO' && sort.order === 'DESC')
            return Util.compareStrings(b.OFE_TITULO, a.OFE_TITULO);

          if (sort.column === 'OFE_VALOR' && sort.order === 'ASC')
            return Util.correctNumberWithComma(a.OFE_VALOR) - Util.correctNumberWithComma(b.OFE_VALOR);
          if (sort.column === 'OFE_VALOR' && sort.order === 'DESC')
            return Util.correctNumberWithComma(b.OFE_VALOR) - Util.correctNumberWithComma(a.OFE_VALOR);
          return 0;
        }),
      ]);
    }
  }, [sort, offers]);

  const copyShareURL = async (offerId: string) => {
    const shareURL = window.location.origin + '/compartilhar-oferta/' + offerId;
    if (await Util.copyToClipboard(shareURL))
      showAlertSuccess({ message: 'Link de compartilhamento copiado com sucesso!' });
  };

  return (
    <table className="table table-bigboy">
      <thead>
        <tr>
          <th className="text-center">Imagem</th>
          <th style={{ minWidth: '80px' }}>
            Título&nbsp;
            {sort.column !== 'OFE_TITULO' && (
              <i className="fas fa-sort clickable" onClick={() => setSort({ column: 'OFE_TITULO', order: 'ASC' })} />
            )}
            {sort.column === 'OFE_TITULO' && sort.order === 'ASC' && (
              <i
                className="fas fa-sort-up clickable"
                onClick={() => setSort({ column: 'OFE_TITULO', order: 'DESC' })}
              />
            )}
            {sort.column === 'OFE_TITULO' && sort.order === 'DESC' && (
              <i className="fas fa-sort-down clickable" onClick={() => setSort({ column: 'none', order: '' })} />
            )}
          </th>
          <th className="th-description">Descrição</th>
          <th className="text-right">Empresa/Cidade</th>
          <th className="text-right" style={{ minWidth: '57px' }}>
            SC$&nbsp;
            {sort.column !== 'OFE_VALOR' && (
              <i className="fas fa-sort clickable" onClick={() => setSort({ column: 'OFE_VALOR', order: 'ASC' })} />
            )}
            {sort.column === 'OFE_VALOR' && sort.order === 'ASC' && (
              <i className="fas fa-sort-up clickable" onClick={() => setSort({ column: 'OFE_VALOR', order: 'DESC' })} />
            )}
            {sort.column === 'OFE_VALOR' && sort.order === 'DESC' && (
              <i className="fas fa-sort-down clickable" onClick={() => setSort({ column: 'none', order: '' })} />
            )}
          </th>
          <th className="text-right"></th>
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan={6}>{/* <LoadingPointsSpin /> */}</td>
          </tr>
        ) : filteredOffers.length === 0 ? (
          <tr>
            <td colSpan={5}>
              <p className="text-color-default">Nenhuma oferta encontrada. Tente alterar os filtros.</p>
            </td>
          </tr>
        ) : (
          filteredOffers.map((offer) => (
            <tr key={offer.OFE_CODIGO}>
              <td className="clickable" onClick={() => onSelectOffer(offer)}>
                <ImagemFlutuante
                  type="zoom"
                  imageSRC={
                    offer.OFE_IMAGEM !== ''
                      ? `${baseURL}/web/uploads/oferta/imagem/${offer.OFE_IMAGEM}`
                      : `${baseURL}/web/uploads/logoLoja/${offer.EMP_LOGOTIPO}`
                  }
                />
              </td>
              <td className="clickable td-name" onClick={() => onSelectOffer(offer)}>
                {offer.OFE_TITULO}
              </td>
              <td className="clickable" onClick={() => onSelectOffer(offer)}>
                {offer.OFE_DESCRICAO.length > 200 ? offer.OFE_DESCRICAO.slice(0, 190) + '...' : offer.OFE_DESCRICAO}
              </td>
              <td className="clickable td-number" onClick={() => onSelectOffer(offer)}>
                <span style={{ color: '#aaa' }}>{offer.EMP_NOMEFANTASIA}</span>
                <br />
                {offer.LEN_CIDADE}
              </td>
              <td className="clickable td-number" onClick={() => onSelectOffer(offer)}>
                {+offer.OFE_VALOR === 0 ? (
                  'À Combinar'
                ) : offer.OFE_TIPO === OfferType.VP ? (
                  Util.formatNumberToVPS(Util.correctNumberWithComma(offer.OFE_VALOR))
                ) : (
                  <>
                    {Util.formatNumberToVPS(Util.correctNumberWithComma(offer.OFE_VALOR))}
                    <br />
                    {Util.formatNumberToMoney(Util.correctNumberWithComma(offer.OFE_VALORVPMIX))}
                    <br />
                    <strong>Total</strong>{' '}
                    {Util.formatNumberWithoutPrefix(Util.correctNumberWithComma(offer.OFE_VALORTOTAL))}
                  </>
                )}
              </td>
              <td className="td-actions">
                <div className="flex-in-column" style={{ height: '120px' }}>
                  {enableShareLink && (
                    <i
                      className="fa fa-share-alt icon-medium color-default clickable"
                      title="Copiar link de compartilhamento"
                      onClick={() => copyShareURL(offer.OFE_CODIGO)}
                    />
                  )}
                </div>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

TableOffers.defaultProps = defaultProps;

export default TableOffers;
