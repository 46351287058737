import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { AuthContext } from '../../context/AuthContext';

import Header from '../../components/login/header';
import Footer from '../../components/login/footer';
import Mensagem from '../../components/mensagens';

import logo from '../../assets/logo.png';
import { EmpType } from '~/enum';

interface FormInput {
  usu_login: string;
  usu_senha: string;
}

const schema = yup.object().shape({
  usu_login: yup.string().required(),
  usu_senha: yup.string().required(),
});

const Login: React.FC = () => {
  const history = useHistory();
  const [showError, setShowError] = useState(false);
  const [messageError, setMessageError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { signIn } = useContext(AuthContext);
  const { register, handleSubmit, errors } = useForm<FormInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormInput): Promise<void> => {
    setShowError(false);
    setIsLoading(true);
    const response = await signIn(data);
    setIsLoading(false);

    if (response.status) {
      if (response.emp_tipo === EmpType.FRANQUEADORA) {
        history.push('franqueadora/dashboard');
      } else if (response.emp_tipo === EmpType.ASSOCIADO) {
        history.push('associado/ofertas');
      } else if (response.emp_tipo === EmpType.FRANQUIA) {
        history.push('franquia/dashboard');
      } else if (response.emp_tipo === EmpType.MASTER_FRANQUIA) {
        history.push('master-franquia/dashboard');
      }
    } else {
      setShowError(true);
      setMessageError(response.mensagem);
    }
  };

  return (
    <div className="wrapper wrapper-full-page background-color-default">
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 122px)',
          minHeight: 'calc(100vh - 122px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className="content">
          <div className="container">
            <div>
              <form className="form" method="" onSubmit={handleSubmit(onSubmit)}>
                <div className="card card-login card-hidden">
                  <div className="card-header">
                    <img src={logo} alt="Logo Socicoin" className="imgLogoLogin" />
                  </div>
                  <div className="card-body ">
                    <div className="card-body">
                      {showError && (
                        <Mensagem
                          color="danger"
                          title="Atenção!"
                          description={messageError || 'Credenciais inválidas'}
                        />
                      )}
                      {Object.keys(errors).length > 0 && (
                        <Mensagem color="danger" title="Atenção!" description="O login e a senha são obrigatórios" />
                      )}
                      <div className="form-group">
                        <label>Login</label>
                        <input
                          autoComplete="username"
                          ref={register}
                          name="usu_login"
                          placeholder="Enter email"
                          className="form-control"
                          onChange={() => {
                            setShowError(false);
                            setMessageError(undefined);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label>Senha</label>
                        <input
                          autoComplete="current-password"
                          ref={register}
                          name="usu_senha"
                          type="password"
                          placeholder="Password"
                          className="form-control"
                          onChange={() => {
                            setShowError(false);
                            setMessageError(undefined);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer ml-auto mr-auto">
                    <button type="submit" className="btn btn-warning btn-wd" disabled={isLoading}>
                      {isLoading ? 'verificando...' : 'Login'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
