import React, { useContext, useEffect, useState } from 'react';

import logoFiltro from '../../../assets/filtro_2.png';

import Header from '../../../components/header';
import Footer from '../../../components/footer';
import Menu from '../../../components/menu';
import IndicatorCard from '~/components/card/IndicatorCard';
import TableWithLinks from '~/components/table/TableWithLinks';
import { Address, Associate, FormAssociate, Image, Segment, State } from '~/types';
import { exportAssociatesToXLS, getAssociatesFranchise, saveAssociate } from '~/service/AssociateService';
import { useErrorHandler } from 'react-error-boundary';
import { AuthContext } from '~/context/AuthContext';
import { closeModal, formatCNPJ, formatCPF, formatPhone, openModal } from '~/utils/util';
import PageLoading from '~/components/loading/PageLoading';
import ViewModalAssociate from '~/views/ModalAssociate';
import ModalFilter from './ModalFilter';
import { matchSorter } from 'match-sorter';
import { showAlertDanger, showAlertSuccess, showAlertWarning } from '~/components/notification';
import ViewAssociados from '~/views/associados';

const CadastroAssociados: React.FC = () => {
  const handleError = useErrorHandler();
  const { user: usuario, company: empresa } = useContext(AuthContext);

  const [associate, setAssociate] = useState<(Associate & Address) | undefined>(undefined);
  const [associates, setAssociates] = useState<(Associate & Address)[]>([]);
  const [associatesFiltered, setAssociatesFiltered] = useState<(Associate & Address)[]>([]);
  const [segments, setSegments] = useState<Segment[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchBoxValue, setSearchBoxValue] = useState('');
  const [isAwaitResponse, setIsAwaitResponse] = useState(false);

  const [cardInactiveAssociates, setCardInactiveAssociates] = useState('');
  const [cardActiveAssociates, setCardActiveAssociates] = useState('');
  const [cardAssociatesVPMIX, setCardAssociatesVPMIX] = useState('');
  const [filterState, setFilterState] = useState({ iti_codigo: [4] });

  useEffect(() => {
    fetchAssociates(filterState.iti_codigo);
  }, [filterState]);

  useEffect(() => {
    setAssociatesFiltered([
      ...matchSorter(associates, searchBoxValue, { keys: ['EMP_RAZAOSOCIAL', 'EMP_NOMEFANTASIA'] }),
    ]);
  }, [searchBoxValue, associates]);

  const fetchAssociates = async (iti_codigo?: number[]) => {
    try {
      setIsLoading(true);
      const { success, payload, error } = await getAssociatesFranchise({
        emp_codigo: empresa.EMP_CODIGO,
        iti_codigo: iti_codigo && iti_codigo.length > 0 ? `(${iti_codigo})` : '',
      });
      setIsLoading(false);

      if (success) {
        const { associados, segmentos, estados, cardAtivos, cardInativos, cardVPMIX } = payload;

        setAssociates(associados);
        setSegments(segmentos);
        setStates(estados);

        setCardInactiveAssociates(cardInativos);
        setCardActiveAssociates(cardAtivos);
        setCardAssociatesVPMIX(cardVPMIX);
      } else {
        showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
        if (error) console.log(error.message);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const openModalAssociateAdd = () => {
    openModal('ModalFormAssociado');
    setAssociate(undefined);
  };

  const openModalAssociateEdit = (associate: Associate & Address) => {
    setAssociate(associate);
    openModal('ModalFormAssociado');
  };

  const handleOnSubmit = async (formAssociate: FormAssociate & { image: Image | undefined }) => {
    setIsAwaitResponse(true);
    const { success, payload, error, type, message } = await saveAssociate({
      ...formAssociate,
      emp_codigo: associate?.EMP_CODIGO,
      emp_tipo: 'A',
      emp_logotipo: associate?.EMP_LOGOTIPO,
      newFile: formAssociate.image?.file,

      usu_codigo: usuario.USU_CODIGO,
      current_emp_codigo: empresa.EMP_CODIGO,
      current_emp_codmatriz: empresa.EMP_CODMATRIZ,
      ...(associate ? { len_codigo: associate.LEN_CODIGO } : undefined),
    });
    setIsAwaitResponse(false);

    if (success) {
      showAlertSuccess({ message: message });
      closeModal('ModalFormAssociado');
      await fetchAssociates(filterState.iti_codigo);
    } else if (type === 'saveFileError') {
      showAlertWarning({
        message: (
          <>
            O associado foi salvo, mas ocorreu um problema ao salvar a logo.
            <br />
            {message}
          </>
        ),
        timeout: 10,
      });
      await fetchAssociates(filterState.iti_codigo);
      setAssociate(undefined);
      setAssociate(payload.empresa);
    } else if (type === 'cnpjDuplicado' || type === 'loginDuplicado') {
      showAlertDanger({ message: message });
    } else {
      showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
    }
  };

  const exportAssociates = () => {
    exportAssociatesToXLS({ emp_codigo: empresa.EMP_CODIGO });
  };

  return (
    <ViewAssociados
      isLoading={isLoading}
      headerConfig={{ title: 'Associados', searchBoxState: { searchBoxValue, setSearchBoxValue } }}
      cards={{
        cardInactiveAssociates,
        cardActiveAssociates,
        cardAssociatesVPMIX,
      }}
      cardHeader={{
        title: 'Lista de associados',
        subtitle: 'Aplique os filtros para encontrar um associado',
        filterAction: () => openModal('filtro'),
        actionAddAssociate: {
          label: 'cadastrar associado',
          iconClass: 'fa fa-plus',
          variant: 'btn-success',
          action: openModalAssociateAdd,
        },
      }}
      ModalAssociate={
        <ViewModalAssociate
          associate={associate}
          segments={segments}
          states={states}
          isAwaitResponse={isAwaitResponse}
          onSubmit={handleOnSubmit}
        />
      }
      ModalFilter={<ModalFilter onSubmit={(p) => setFilterState({ iti_codigo: p })} />}
      associates={associatesFiltered}
      openModalAssociateAdd={openModalAssociateAdd}
      openModalAssociateEdit={openModalAssociateEdit}
      exportAssociates={exportAssociates}
    />
  );
};

export default CadastroAssociados;
