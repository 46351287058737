import React, { useContext, useEffect, useState } from 'react';

import Header from '~/components/header';
import Footer from '~/components/footer';
import Menu from '~/components/menu';

import IndicatorCard from '~/components/card/IndicatorCard';
import PageLoading from '~/components/loading/PageLoading';
import LastTransactions from './LastTransactions';

import { getDashboardAssociado } from '~/service/DashboardService';
import { formatNumberWithoutPrefix, formatNumberToVPS, correctNumberWithComma } from '~/utils/util';
import { SegmentDashboard } from '~/types';
import { AuthContext } from '~/context/AuthContext';
import { showAlertDanger } from '~/components/notification';

const Dashboard: React.FC = () => {
  const { company: empresa } = useContext(AuthContext);

  const [cardLimit, setCardLimit] = useState('');
  const [cardCredit, setCardCredit] = useState('');
  const [cardBuys, setCardBuys] = useState('');
  const [cardSells, setCardSells] = useState('');

  const [lastBuys, setLastBuys] = useState([]);
  const [lastSells, setLastSells] = useState([]);

  const [qtdBuy, setQtdBuy] = useState('');
  const [qtdSell, setQtdSell] = useState('');
  const [ticketAverageBuy, setTicketAverageBuy] = useState('');
  const [ticketAverageSell, setTicketAverageSell] = useState('');

  const [segments, setSegments] = useState<Array<SegmentDashboard & { percentFormated: string }>>([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDashboardAssociado({
      EMP_TIPO: empresa.EMP_TIPO,
      EMP_CODIGO: empresa.EMP_CODIGO,
      EMP_CODMATRIZ: empresa.EMP_CODMATRIZ,
    }).then(({ payload }) => {
      const regulator = +payload.QUADRO0.TOTAL_REGULADOR.replace(',', '.');
      const limit = +payload.QUADRO0.LIMITE_APROVADO.replace(',', '.');
      const buys = +payload.QUADRO0.TOTAL_COMPRA.replace(',', '.');
      const sells = +payload.QUADRO0.TOTAL_VENDA.replace(',', '.');

      const credit = limit + (buys + sells - regulator);

      setCardLimit(formatNumberWithoutPrefix(limit));
      setCardCredit(formatNumberWithoutPrefix(credit));
      setCardBuys(formatNumberWithoutPrefix(buys * -1));
      setCardSells(formatNumberWithoutPrefix(sells));

      setLastBuys(payload.QUADRO3);
      setLastSells(payload.QUADRO4);

      setQtdBuy(payload.QUADRO1.QTD_COMPRA);
      setQtdSell(payload.QUADRO1.QTD_VENDA);
      setTicketAverageBuy(payload.QUADRO1.TICKET_MEDIO_COMPRA);
      setTicketAverageSell(payload.QUADRO1.TICKET_MEDIO_VENDA);

      setSegments(
        payload.QUADRO2.map((q: SegmentDashboard) => ({
          ...q,
          percentFormated: Number(q.PERCENTUAL.replace(',', '.')).toFixed(2),
        }))
          .sort(
            (a: SegmentDashboard & { percentFormated: string }, b: SegmentDashboard & { percentFormated: string }) =>
              +b.percentFormated - +a.percentFormated
          )
          .splice(0, 10)
      );

      setIsLoading(false);
    });
  }, []);

  return (
    <div className="wrapper">
      <Menu />

      <div className="main-panel">
        <Header title="Dashboard" showBalance />

        <div className="content">
          {isLoading && <PageLoading />}
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <IndicatorCard
                  icon="nc-icon nc-money-coins"
                  label="Limite SC$"
                  value={cardLimit}
                  title="Limite Aprovado"
                />
              </div>
              <div className="col-lg-3 col-sm-6">
                <IndicatorCard
                  icon="nc-icon nc-money-coins text-warning"
                  label="Disponível SC$"
                  value={cardCredit}
                  title="Crédito disponível"
                />
              </div>
              <div className="col-lg-3 col-sm-6">
                <IndicatorCard
                  icon="nc-icon nc-money-coins text-danger"
                  label="Compras SC$"
                  value={cardBuys}
                  title="Todal de compras"
                />
              </div>
              <div className="col-lg-3 col-sm-6">
                <IndicatorCard
                  icon="nc-icon nc-money-coins text-success"
                  label="Vendas SC$"
                  value={cardSells}
                  title="Total de Vendas"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="card table-with-links">
                  <LastTransactions
                    loading={isLoading}
                    title="Compras"
                    subtitle="Suas 5 últimas transações de COMPRA"
                    tableData={lastBuys}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="card table-with-links">
                  <LastTransactions
                    loading={isLoading}
                    title="Vendas"
                    subtitle="Suas 5 últimas transações de VENDA"
                    tableData={lastSells}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card table-with-links">
                  <div className="card-header ">
                    <h4 className="card-title">Outros Indicadores</h4>
                    <p className="card-category">Compra, venda, ticket médio</p>
                  </div>
                  <div className="card-body">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Indicador</th>
                          <th className="text-right">Qtde ou SC$</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan={2}>{/* <LoadingSpin /> */}</td>
                          </tr>
                        ) : (
                          <>
                            <tr>
                              <td>Quantidade de negociações de COMPRA</td>
                              <td className="text-right text-danger">{qtdBuy}</td>
                            </tr>
                            <tr>
                              <td>Quantidade de negociações de VENDA</td>
                              <td className="text-right text-danger">{qtdSell}</td>
                            </tr>
                            <tr>
                              <td>Ticket Médio de COMPRA</td>
                              <td className="text-right text-danger">
                                {formatNumberToVPS(+ticketAverageBuy.replace(',', '.'))}
                              </td>
                            </tr>
                            <tr>
                              <td>Ticket Médio de VENDA</td>
                              <td className="text-right text-danger">
                                {formatNumberToVPS(+ticketAverageSell.replace(',', '.'))}
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card table-with-links">
                  <div className="card-header ">
                    <h4 className="card-title">% de COMPRA por Segmento</h4>
                    <p className="card-category">Apenas as 10 maiores</p>
                  </div>
                  <div className="card-body">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Segmento</th>
                          <th className="text-right">%</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan={2}>{/* <LoadingSpin /> */}</td>
                          </tr>
                        ) : (
                          segments.map((segment, i) => (
                            <tr key={i}>
                              <td>{segment.SEG_DESCRICAO}</td>
                              <td className="text-right text-danger">
                                <span className="badge badge-orange">{segment.percentFormated} %</span>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
