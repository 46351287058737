import React, { useContext, useEffect, useState } from 'react';

import Header from '../../../components/header';
import Footer from '../../../components/footer';
import Menu from '../../../components/menu';

import ModalBaixar from './ModalBaixar';
import ModalFilter from './ModalFilter';

import logoFiltro from '../../../assets/filtro_2.png';
import { getMyVouchers } from '~/service/associado/VoucherService';
import { AuthContext } from '~/context/AuthContext';
import moment from 'moment';
import { VoucherStatus } from '~/enum';
import { Voucher } from '~/types';
import LoadingPointsSpin from '~/components/loading/LoadingPointsSpin';
import { correctNumberWithComma, formatNumberWithoutPrefix } from '~/utils/util';
import PageLoading from '~/components/loading/PageLoading';

const BaixarVoucher: React.FC = () => {
  const { company: empresa } = useContext(AuthContext);
  const [searchBoxValue, setSearchBoxValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [voucher, setVoucher] = useState({} as Voucher);
  const [vouchers, setVouchers] = useState([] as Voucher[]);

  const [filters, setFilters] = useState({
    startDate: moment().clone().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().clone().endOf('month').format('YYYY-MM-DD'),
    status: [] as Array<{ value: string }>,
  });

  useEffect(() => {
    fetchMyVouchers();
  }, []);

  const fetchMyVouchers = async () => {
    setIsLoading(true);

    let status = '(';
    filters.status.forEach((s, i) => {
      i === 0 ? (status += `'${s.value}'`) : (status += `,'${s.value}'`);
    });
    status += ')';

    const { payload } = await getMyVouchers({
      emp_codigo: empresa.EMP_CODIGO,
      data_inicial: moment(filters.startDate).format('DD/MM/YYYY'),
      data_final: moment(filters.endDate).format('DD/MM/YYYY'),
      // vou_status: '',
      vou_status: filters.status.length === 0 ? null : status,
    });
    setVouchers(payload.vouchers);
    setIsLoading(false);
  };

  return (
    <div className="wrapper">
      <Menu />

      <div className="main-panel">
        <Header
          title="Baixar voucher"
          showBalance
          searchBoxState={{ searchBoxValue: searchBoxValue, setSearchBoxValue: setSearchBoxValue }}
        />

        <div className="content">
          {isLoading && <PageLoading />}
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-sm-6">
                <div className="card card-stats">
                  <div className="card-body ">
                    <div className="row">
                      <div className="col-5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-icon nc-settings-90 text-warning"></i>
                        </div>
                      </div>
                      <div className="col-7">
                        <div className="numbers">
                          <p className="card-category">Quantidade</p>
                          <h4 className="card-title card-title-dashboard">0</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer ">
                    <hr />
                    <div className="stats">Quantidade de Voucher</div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="card card-stats">
                  <div className="card-body ">
                    <div className="row">
                      <div className="col-5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-icon nc-settings-90 text-warning"></i>
                        </div>
                      </div>
                      <div className="col-7">
                        <div className="numbers">
                          <p className="card-category">Total SC$ </p>
                          <h4 className="card-title card-title-dashboard">0</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer ">
                    <hr />
                    <div className="stats">Valor total de Voucher</div>
                  </div>
                </div>
              </div>
            </div>

            <ModalFilter
              filtersState={{ filters: filters, setFilters: setFilters }}
              fetchMyVouchers={fetchMyVouchers}
            />
            <ModalBaixar voucher={voucher} fetchMyVouchers={fetchMyVouchers} />

            <div className="row">
              <div className="col-md-12">
                <div className="card table-with-links">
                  <div className="card-header " style={{ display: 'flex', justifyContent: 'center' }}>
                    <div>
                      <h4 className="card-title">
                        Vouchers vendidos{' '}
                        <img
                          src={logoFiltro}
                          style={{ width: 30, height: 30, cursor: 'pointer' }}
                          data-toggle="modal"
                          data-target="#filtro"
                        />
                      </h4>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-left">Últ. Movimentação</th>
                            <th className="text-left">Status</th>
                            <th className="text-left">Orçamento</th>
                            <th className="text-left">Oferta</th>
                            <th className="text-center">Valor</th>
                            <th className="text-left">Comprador</th>
                            <th className="text-left">Franquia</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <tr>
                              <td colSpan={7}>{/* <LoadingPointsSpin /> */}</td>
                            </tr>
                          ) : vouchers.length === 0 ? (
                            <tr>
                              <td colSpan={7}>
                                <p className="text-color-default">Nenhum voucher encontrado.</p>
                              </td>
                            </tr>
                          ) : (
                            vouchers.map((voucher) => (
                              <tr onClick={() => setVoucher(voucher)} key={voucher.VOU_CODIGO}>
                                <td className="text-center">{voucher.VOU_ULTIMAATUALIZACAO.split(' ')[0]}</td>
                                <td className="text-left">{voucher.VOU_STATUS}</td>
                                <td className="text-center">{voucher.ORC_CODIGO}</td>
                                <td className="text-left">{voucher.OFE_TITULO}</td>
                                <td className="text-center">
                                  {formatNumberWithoutPrefix(+correctNumberWithComma(voucher.VOU_VALOR))}
                                </td>
                                <td className="text-left">{voucher.EMP_ASSOCIADO}</td>
                                <td className="text-left">{voucher.EMP_FRANQUIA}</td>
                                <td className="text-center">
                                  {voucher.VOU_STATUS === VoucherStatus.ABERTO ? (
                                    <button
                                      className="btn btn-sm btn-success"
                                      data-toggle="modal"
                                      data-target="#baixar"
                                    >
                                      baixar
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-sm btn-default btn-outline"
                                      data-toggle="modal"
                                      data-target="#baixar"
                                    >
                                      ver
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default BaixarVoucher;
