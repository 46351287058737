import React, { createContext, useState } from 'react';
import { Helmet } from 'react-helmet';

import Header from '~/components/header';
import Footer from '~/components/footer';
import Menu from '~/components/menu';
import PageLoading from '~/components/loading/PageLoading';
import { LayoutMainProps } from '~/types';

interface HeaderOptions {}

interface LayoutMainOptions {
  headerOptions: HeaderOptions;
}

const LayoutMainContext = createContext<LayoutMainOptions>({} as LayoutMainOptions);

const LayoutMain: React.FunctionComponent<LayoutMainProps> = ({ children, isLoading, ...props }) => {
  return (
    <div className="wrapper">
      <Helmet title={`${props.title} - Socicoin`} />
      <Menu {...props} />

      <div className="main-panel">
        <Header {...props} />

        <div className="content">
          {isLoading && <PageLoading />}
          <div className="container-fluid">
            <LayoutMainContext.Provider value={{ headerOptions: {} }}>{children}</LayoutMainContext.Provider>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default LayoutMain;
export { LayoutMainContext };
