import React, { useContext, useEffect, useState } from 'react';
import Header from '~/components/header';
import Footer from '~/components/footer';
import Menu from '~/components/menu';
import ModalFilter from '~/components/modal/negociacoes/ModalFilter';
import logoFiltro from '~/assets/filtro_2.png';
import { Negotiation, NegotiationHistory, Offer } from '~/types';
import {
  getNegotiations,
  getNegotiationHistory,
  permuteOfferBuy,
  permuteOfferSell,
  resendPayment,
} from '~/service/NegotiationService';
import moment from 'moment';
import { AuthContext } from '~/context/AuthContext';
import TableNegotiations from '~/components/table/TableNegotiations';
import IndicatorCard from '~/components/card/IndicatorCard';
import { NegotiationSituation, NegotiationType, OfferType } from '~/enum';
import {
  closeModal,
  correctNumberWithComma,
  formatAndCompareDate,
  isPopupBlocked,
  openItauShopline,
  openModal,
  parseTransactionArray,
  openInNewWindow,
} from '~/utils/util';
import SweetAlert from 'react-bootstrap-sweetalert';
import ModalNegotiationHistory from '~/components/modal/negociacoes/ModalNegotiationHistory';
import ModalNegotiationResponse from './ModalNegotiationResponse';
import ModalOfferDetails from '~/components/modal/ModalOfferDetails';
import { getOfferDetails } from '~/service/OfferService';
import { printVoucher } from '~/service/associado/VoucherService';
import ModalPayCommission from '~/components/modal/ModalPayCommission';
import { showAlertDanger, showAlertSuccess } from '~/components/notification';
import PageLoading from '~/components/loading/PageLoading';
import { submitPaymentAsaas } from '~/service/PaymentAsaas';

const Negociacoes: React.FC = () => {
  const { company: empresa, user: usuario, changeBalance } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [negotiation, setNegotiation] = useState({} as Negotiation);
  const [negotiations, setNegotiations] = useState([] as Array<Negotiation>);
  const [cardRecusado, setCardRecusado] = useState('');
  const [cardNegociando, setCardNegociando] = useState('');
  const [cardFechado, setCardFechado] = useState('');
  const [alert, setAlert] = useState(<></>);

  const [offer, setOffer] = useState({} as Offer);
  const [history, setHistory] = useState([] as NegotiationHistory[]);
  const [isLoadingModal, setIsLoadingModal] = useState(false);

  const [filters, setFilters] = useState({
    startDate: moment().clone().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().clone().endOf('month').format('YYYY-MM-DD'),
    status: [] as Array<{ value: string }>,
  });

  useEffect(() => {
    fetchMyNegotiations();

    if (isPopupBlocked()) popupSweetAlert();
  }, []);

  const fetchMyNegotiations = async () => {
    setIsLoading(true);

    let status = '(';
    filters.status.forEach((s, i) => {
      i === 0 ? (status += `'${s.value}'`) : (status += `,'${s.value}'`);
    });
    status += ')';

    const { payload, success } = await getNegotiations({
      data_inicial: moment(filters.startDate).format('DD/MM/YYYY'),
      data_final: moment(filters.endDate).format('DD/MM/YYYY'),
      one_status: filters.status.length === 0 ? null : filters.status.map(({ value }) => value),
      emp_codigo: empresa.EMP_CODIGO,
      emp_tipo: empresa.EMP_TIPO,
    });
    setIsLoading(false);

    if (success) {
      changeBalance(payload.saldo);

      const orcamentos = payload.orcamentos
        .map((negotiation: Negotiation) =>
          negotiation.ONE_VALOR !== '' && negotiation.ONE_VALOR.indexOf(',') >= negotiation.ONE_VALOR.length - 3
            ? { ...negotiation, ONE_VALOR: negotiation.ONE_VALOR.replace(',', '.') }
            : { ...negotiation }
        )
        .sort((a: Negotiation, b: Negotiation) => {
          const perDate = formatAndCompareDate(b.ONE_DATA, a.ONE_DATA);
          return perDate !== 0 ? perDate : +b.ORC_CODIGO - +a.ORC_CODIGO;
        });

      setNegotiations(orcamentos);

      setCardRecusado(
        payload.orcamentos.reduce((accumulator: number, currentValue: Negotiation) => {
          if (currentValue.ONE_STATUS === NegotiationSituation.Recusado) return accumulator + 1;
          else return accumulator;
        }, 0)
      );
      setCardNegociando(
        payload.orcamentos.reduce((accumulator: number, currentValue: Negotiation) => {
          if (currentValue.ONE_STATUS === NegotiationSituation.Negociando) return accumulator + 1;
          else return accumulator;
        }, 0)
      );
      setCardFechado(
        payload.orcamentos
          .reduce((accumulator: number, currentValue: Negotiation) => {
            if (currentValue.ONE_STATUS === NegotiationSituation.Fechado)
              return accumulator + +correctNumberWithComma(currentValue.ONE_VALOR);
            else return accumulator;
          }, 0)
          .toFixed(2)
      );
    }
  };

  const popupSweetAlert = () => {
    setAlert(
      <SweetAlert
        danger
        title="Ops!"
        onConfirm={() => document.location.reload()}
        onCancel={() => {}}
        confirmBtnBsStyle="info"
        allowEscape={false}
      >
        <>
          <b>O Bloqueador de Pop-up está ativado para o sistema.</b>
          <br />
          <br />
          Favor permitir em seu navegador que o site <b>{window.location.origin}</b> possa abrir Pop-up!
          <br />
          <br />
          <b>Chrome:</b> Configurações {'>'} Privacidade {'>'} Configurações de Conteúdo {'>'} Pop-ups {'>'} Gerenciar
          Exceções
          <br />
          <br />
          <b>Firefox:</b> Opções {'>'} Conteúdo {'>'} Janelas popup {'>'} Exceções
          <br />
          <br />
          <b>Safari Mac:</b> Safari {'>'} Preferências {'>'} Websites {'>'} Ative
          <br />
          <br />
          <b>Safari Iphone:</b> Configurações {'>'} Safari {'>'} Bloquear pop-ups {'>'} Ative
        </>
      </SweetAlert>
    );
  };

  const fetchOfferDetails = async (negotiation: Negotiation) => {
    setIsLoadingModal(true);
    setOffer({} as Offer);
    openModal('detalhesOferta');

    const { payload } = await getOfferDetails(negotiation.OFE_CODIGO);

    setOffer(payload.oferta);
    setIsLoadingModal(false);
  };

  const fetchNegotiationHistory = async (orc_codigo: string) => {
    setIsLoadingModal(true);

    const { payload } = await getNegotiationHistory(orc_codigo);
    setHistory(payload.historico);
    setIsLoadingModal(false);
  };

  const openModalPayCommission = (offer: Negotiation) => {
    setNegotiation(offer);
    openModal('ModalPayCommission');
  };

  const permuteOfferHandler = async (fpa_codigo: number) => {
    if (negotiation.EMP_FRANQUIA_ASAAS_ATIVO === 'S') {
      handlePaymentAsaas(negotiation, fpa_codigo);
    } else {
      const transaction = parseTransactionArray(negotiation, negotiation.ORC_TIPO == NegotiationType.VENDA);

      if (transaction) {
        const body = {
          emp_codigo: empresa.EMP_CODIGO,
          emp_codmatriz: empresa.EMP_CODMATRIZ,
          usu_codigo: usuario.USU_CODIGO,
          ofe_codigo: negotiation.OFE_CODIGO,
          ofe_valor: negotiation.ONE_VALOR,
          fpa_codigo: fpa_codigo,
          orc_codigo: negotiation.ORC_CODIGO,
          one_descricao: '',
        };
        const {
          success,
          message,
          payload: { dados },
        } = negotiation.ORC_TIPO === NegotiationType.VENDA
          ? await permuteOfferSell({ ...body, tra_codigo: transaction.tra_codigo })
          : await permuteOfferBuy(body);

        if (!success) {
          showAlertDanger({ message });
        } else {
          openItauShopline(dados);
          closeModal('ModalPayCommission');
          fetchMyNegotiations();
        }
      }
    }

    closeModal('ModalPayCommission');
  };

  const execTableAction = async (
    negotiation: Negotiation,
    action: 'negotiationRespose' | 'payCommission' | 'printVoucher' | 'showHistory'
  ) => {
    if (action === 'negotiationRespose') {
      setNegotiation(negotiation);
      openModal('responder');
    } else if (action === 'payCommission' && negotiation.ONE_STATUS === NegotiationSituation.Fechado) {
      if (negotiation.EMP_FRANQUIA_ASAAS_ATIVO === 'S') {
        if (
          !negotiation.LAN_ID_PAYMENT ||
          (negotiation?.ORC_TIPO == 'Venda'
            ? negotiation.LAN_RECEBIDODESTINATARIO === 'S'
            : negotiation.LAN_RECEBIDOSOLICITANTE === 'S')
        ) {
          openModalPayCommission(negotiation);
        } else if (negotiation?.ORC_TIPO == 'Venda' && negotiation?.LAN_INVOICE_VENDEDOR) {
          openInNewWindow(negotiation.LAN_INVOICE_VENDEDOR);
        } else if (negotiation?.ORC_TIPO == 'Compra' && negotiation?.LAN_INVOICE_COMPRADOR) {
          openInNewWindow(negotiation.LAN_INVOICE_COMPRADOR);
        } else {
          await handlePaymentAsaas(negotiation);
        }
      } else {
        const transaction = parseTransactionArray(negotiation, negotiation.ORC_TIPO == NegotiationType.VENDA);

        if (!transaction || transaction.tra_pedido === undefined || transaction.tra_pedido === '') {
          openModalPayCommission(negotiation);
        } else {
          await handleResendPayment({
            ofe_codigo: negotiation.OFE_CODIGO,
            emp_codigo: empresa.EMP_CODIGO.toString(),
            emp_codmatriz: empresa.EMP_CODMATRIZ.toString(),
            tra_tipo: negotiation.ORC_TIPO,
            tra_codigo: transaction.tra_codigo,
          });
        }
      }

      fetchMyNegotiations();
    } else if (action === 'showHistory') {
      fetchNegotiationHistory(negotiation.ORC_CODIGO);
      openModal('verHistorico');
    } else if (action === 'printVoucher') {
      printVoucher({ orc_codigo: negotiation.ORC_CODIGO, ofe_codigo: negotiation.OFE_CODIGO });
    }
  };

  const handleResendPayment = async ({
    ofe_codigo,
    emp_codigo,
    emp_codmatriz,
    tra_tipo,
    tra_codigo,
  }: {
    ofe_codigo: string;
    emp_codigo: string;
    emp_codmatriz: string;
    tra_tipo: 'Compra' | 'Venda';
    tra_codigo: string;
  }) => {
    const {
      payload: { dados },
    } = await resendPayment({
      ofe_codigo,
      emp_codigo,
      emp_codmatriz,
      tra_tipo,
      tra_codigo,
    });
    openItauShopline(dados);
  };

  const handlePaymentAsaas = async (negotiation: Negotiation, fpa_codigo?: number) => {
    setIsLoading(true);

    const response = await submitPaymentAsaas(
      empresa.EMP_CODIGO,
      empresa.EMP_CODMATRIZ,
      negotiation.ORC_TIPO === 'Compra' ? true : false,
      fpa_codigo ? fpa_codigo : negotiation.FPA_CODIGO_PAYMENT,
      negotiation.ORC_TIPO === 'Compra' ? negotiation.LAN_VALOR_COMPRADOR : negotiation.LAN_VALOR_VENDEDOR,
      negotiation.ORC_TIPO === 'Compra' ? negotiation.LAN_CODIGO_COMPRADOR : negotiation.LAN_CODIGO_VENDEDOR,
      negotiation.LAN_ID_PAYMENT
    );

    setIsLoading(false);

    if (response.success) {
      showAlertSuccess({ message: response.message, timeout: 0 });
    } else {
      showAlertDanger({ message: response.message, timeout: 0 });
    }

    if (response.payload?.data.idAssas) {
      window.open(response.payload.data.invoiceUrl, '_blank');
    } else {
      let message = '';
      if (Boolean(response.payload?.data.autenticacao)) {
        message = 'Integração de cobrança não permitida.\nEntre em contato com a franquia do vendedor!';
      } else if (Boolean(response.payload?.data.associado)) {
        message = 'Seu email ou telefone está inválido.\nVerifique com o responsável!';
      } else {
        message = 'Não conseguimos enviar o email com a cobrança!';
      }

      setTimeout(() => {
        showAlertDanger({ message, timeout: 0 });
      }, 3000);
    }
  };

  return (
    <>
      <div className="wrapper">
        <Menu />
        <div className="main-panel">
          <Header title="Negociações" showBalance />

          <div className="content">
            {isLoading && <PageLoading />}
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-4 col-sm-6">
                  <IndicatorCard
                    label="Recusado"
                    value={cardRecusado}
                    title="Negociações recusadas"
                    icon="nc-icon nc-icon nc-bell-55 text-danger"
                  />
                </div>
                <div className="col-lg-4 col-sm-6">
                  <IndicatorCard
                    label="Negociando"
                    value={cardNegociando}
                    title="Negociações em andamento"
                    icon="nc-icon nc-icon nc-bell-55 text-warning"
                  />
                </div>
                <div className="col-lg-4 col-sm-6">
                  <IndicatorCard
                    label="Fechado SC$"
                    value={cardFechado}
                    title="Negociações fechadas"
                    icon="nc-icon nc-icon nc-bell-55 text-success"
                  />
                </div>
              </div>

              <ModalFilter
                filtersState={{ filters: filters, setFilters: setFilters }}
                fetchMyNegotiations={fetchMyNegotiations}
              />
              {alert}

              <div className="row">
                <div className="col-md-12">
                  <div className="card table-with-links">
                    <div className="card-header " style={{ display: 'flex', justifyContent: 'center' }}>
                      <div>
                        <h4 className="card-title">
                          Minhas negociações{' '}
                          <img
                            src={logoFiltro}
                            style={{ width: 30, height: 30, cursor: 'pointer' }}
                            data-toggle="modal"
                            data-target="#filtro"
                          />
                        </h4>
                        <div style={{ flexDirection: 'column', marginTop: 10 }}>
                          <span style={{ fontSize: 14 }}>
                            <i className="fa fa-comments" style={{ color: '#fba534', fontSize: 16 }} /> responder
                          </span>
                          <span style={{ fontSize: 14, marginLeft: 15 }}>
                            <i className="fa fa-money-bill-alt" style={{ color: '#8ece31', fontSize: 16 }} /> pagar
                            comissão
                          </span>
                          <span style={{ fontSize: 14, marginLeft: 15 }}>
                            <i className="fa fa-list" style={{ fontSize: 16 }} /> ver histórico
                          </span>
                          <span style={{ fontSize: 14, marginLeft: 15 }}>
                            <i className="fa fa-print" style={{ fontSize: 16 }} /> imprimir voucher
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <TableNegotiations
                          isLoading={isLoading}
                          negotiations={negotiations}
                          onSelectNegotiation={fetchOfferDetails}
                          execTableAction={execTableAction}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <ModalOfferDetails offer={offer} isLoading={isLoadingModal} />
      <ModalNegotiationResponse negotiation={negotiation} fetchMyNegotiations={fetchMyNegotiations} />
      <ModalNegotiationHistory isLoading={isLoadingModal} history={history} />
      <ModalPayCommission
        emp_codigo={empresa.EMP_CODIGO.toString()}
        onSubmit={permuteOfferHandler}
        negotiation={{
          ofe_codigo: negotiation.OFE_CODIGO,
          emp_codigo: empresa.EMP_CODIGO,
          e_compra: negotiation.ORC_TIPO === NegotiationType.COMPRA,
          valor: negotiation.ONE_VALOR,
          e_vpmix: negotiation.OFE_TIPO === OfferType.VPMIX,
          isPaid:
            negotiation?.ORC_TIPO == 'Venda'
              ? negotiation.LAN_RECEBIDODESTINATARIO === 'S'
              : negotiation.LAN_RECEBIDOSOLICITANTE === 'S',
        }}
      />
    </>
  );
};

export default Negociacoes;
