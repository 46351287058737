import React, { useContext, useEffect, useState } from 'react';

import { getDashboardDataRaioX, getDashboardDataRaioXAssociado } from '~/service/DashboardService';
import { AuthContext } from '~/context/AuthContext';
import { Associate, DashboardTransactions } from '~/types';
import { correctNumberWithComma, formatNumberWithoutPrefix } from '~/utils/util';
import { showAlertDanger } from '~/components/notification';
import ViewRaioXAssociado from '~/views/raioXAssociado';

const RaioXAssociado: React.FunctionComponent = () => {
  const { company } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);

  const [cardBalance, setCardBalance] = useState('');
  const [cardLimit, setCardLimit] = useState('');
  const [cardAverageTicket, setCardAverageTicket] = useState('');
  const [cardCredit, setCardCredit] = useState('');

  const [qtdBuy, setQtdBuy] = useState('');
  const [qtdSell, setQtdSell] = useState('');

  const [associate, setAssociate] = useState<Associate | undefined>(undefined);
  const [associates, setAssociates] = useState<Associate[]>([]);

  const [associateCodChosen, setAssociateCodChosen] = useState<{ value: string; label: string } | undefined>(undefined);

  const [maioresTransacoes, setMaioresTransacoes] = useState<DashboardTransactions[]>([]);
  const [ultimasTransacoes, setUltimasTransacoes] = useState<DashboardTransactions[]>([]);

  const [graficoTransacoes, setGraficoTransacoes] = useState<{ name: string; buy: number; sell: number }[]>([]);

  useEffect(() => {
    fetchDashboardDataRaioX();
  }, []);

  const fetchDashboardDataRaioX = async () => {
    setIsLoading(true);
    const { success, payload } = await getDashboardDataRaioX({ emp_codigo: company.EMP_CODIGO });

    if (success) {
      const { emp_codigo_associado, emp_razaosocial, associados, dados, associado, maioresTransacoes, ultimasTransacoes } = payload;

      setAssociates(associados);
      setAssociateCodChosen({ value: emp_codigo_associado, label: emp_razaosocial });
      mountDashboard({
        card: { ...dados.QUADRO0, ...dados.QUADRO1 },
        associate: associado,
        biggestTransactions: maioresTransacoes,
        lastTransactions: ultimasTransacoes,
      });
    } else {
      showAlertDanger({ message: 'Ocorreu um erro interno. Tente recarregar a página.' });
    }

    setIsLoading(false);
  };

  const fetchDashboardDataRaioXAssociado = async (emp_codigo_associado: number | string) => {
    setIsLoading(true);
    const { success, payload } = await getDashboardDataRaioXAssociado({ emp_codigo_associado });

    if (success) {
      const { dados, associado, maioresTransacoes, ultimasTransacoes } = payload;
      mountDashboard({
        card: { ...dados.QUADRO0, ...dados.QUADRO1 },
        associate: associado,
        biggestTransactions: maioresTransacoes,
        lastTransactions: ultimasTransacoes,
      });
    } else {
      showAlertDanger({ message: 'Ocorreu um erro interno. Tente recarregar a página.' });
    }

    setIsLoading(false);
  };

  const mountDashboard = ({ card, associate, biggestTransactions, lastTransactions }: any) => {
    const regulator = +card.TOTAL_REGULADOR.replace(',', '.');
    const limit = +card.LIMITE_APROVADO.replace(',', '.');
    const buys = +card.TOTAL_COMPRA.replace(',', '.');
    const sells = +card.TOTAL_VENDA.replace(',', '.');

    const balance = buys + sells - regulator;
    const credit = limit + (buys + sells - regulator);

    setCardBalance(formatNumberWithoutPrefix(balance));
    setCardLimit(formatNumberWithoutPrefix(limit));
    setCardAverageTicket(formatNumberWithoutPrefix(correctNumberWithComma(card.TICKET_MEDIO_COMPRA)));
    setCardCredit(formatNumberWithoutPrefix(credit));

    setGraficoTransacoes([{ name: 'Total', buy: correctNumberWithComma(card.TOTAL_COMPRA) * -1, sell: correctNumberWithComma(card.TOTAL_VENDA) }]);
    setAssociate(associate);

    setQtdBuy(card.QTD_COMPRA);
    setQtdSell(card.QTD_VENDA);

    setMaioresTransacoes(biggestTransactions);
    setUltimasTransacoes(lastTransactions);
  };

  return (
    <ViewRaioXAssociado
      isLoading={isLoading}
      cards={{
        cardBalance,
        cardLimit,
        cardAverageTicket,
        cardCredit,
        qtdBuy,
        qtdSell,
      }}
      fetchDashboardDataRaioXAssociado={fetchDashboardDataRaioXAssociado}
      associate={associate}
      associates={associates}
      associateCodChosenState={{
        associateCodChosen,
        setAssociateCodChosen,
      }}
      maioresTransacoes={maioresTransacoes}
      ultimasTransacoes={ultimasTransacoes}
      graficoTransacoes={graficoTransacoes}
    />
  );
};

export default RaioXAssociado;
