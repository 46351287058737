import React, { useContext, useEffect, useState } from 'react';

import Header from '../../../components/header';
import Footer from '../../../components/footer';
import Menu from '../../../components/menu';

import ModalOfferDetails from '~/components/modal/ModalOfferDetails';
import ModalFilter from '~/components/modal/negociacoes/ModalFilter';
import ModalNegotiationHistory from '~/components/modal/negociacoes/ModalNegotiationHistory';

import { FilterExportAssociateStatement, Negotiation, NegotiationHistory, Offer } from '~/types';
import { AuthContext } from '~/context/AuthContext';
import moment from 'moment';
import { correctNumberWithComma, formatAndCompareDate, formatNumberWithoutPrefix, openModal } from '~/utils/util';
import {
  exportAssociatesStatementToXLS,
  exportAssociateStatementToPDF,
  exportAssociateStatementToXLS,
  getNegotiationHistory,
  getNegotiations,
} from '~/service/NegotiationService';
import IndicatorCard from '~/components/card/IndicatorCard';
import PageLoading from '~/components/loading/PageLoading';
import { NegotiationSituation, NegotiationType } from '~/enum';
import { getOfferDetails } from '~/service/OfferService';
import TableWithLinks from '~/components/table/TableWithLinks';
import CardHeader from '~/components/card/CardHeader';
import ExportAssociateStatement from './ExportAssociateStatement';
import { printVoucher } from '~/service/associado/VoucherService';

const Negociacoes: React.FC = () => {
  const { company: empresa, user: usuario } = useContext(AuthContext);

  const [cardRecusado, setCardRecusado] = useState('');
  const [cardNegociando, setCardNegociando] = useState('');
  const [cardFechado, setCardFechado] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [negotiation, setNegotiation] = useState<Negotiation>({} as Negotiation);
  const [negotiations, setNegotiations] = useState<Negotiation[]>([]);
  const [offer, setOffer] = useState({} as Offer);

  const [history, setHistory] = useState([] as NegotiationHistory[]);
  const [isLoadingModal, setIsLoadingModal] = useState(false);

  const [filters, setFilters] = useState({
    startDate: moment().clone().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().clone().endOf('month').format('YYYY-MM-DD'),
    status: [] as Array<{ value: string }>,
  });

  useEffect(() => {
    fetchAssociateNegotiations();
  }, []);

  const fetchAssociateNegotiations = async () => {
    setIsLoading(true);

    let status = '(';
    filters.status.forEach((s, i) => {
      i === 0 ? (status += `'${s.value}'`) : (status += `,'${s.value}'`);
    });
    status += ')';

    const { payload } = await getNegotiations({
      data_inicial: moment(filters.startDate).format('DD/MM/YYYY'),
      data_final: moment(filters.endDate).format('DD/MM/YYYY'),
      one_status: filters.status.length === 0 ? null : filters.status.map(({ value }) => value),
      emp_codigo: empresa.EMP_CODIGO,
      emp_tipo: empresa.EMP_TIPO,
    });

    const orcamentos = payload.orcamentos
      .map((negotiation: Negotiation) =>
        negotiation.ONE_VALOR !== '' && negotiation.ONE_VALOR.indexOf(',') >= negotiation.ONE_VALOR.length - 3
          ? { ...negotiation, ONE_VALOR: negotiation.ONE_VALOR.replace(',', '.') }
          : { ...negotiation }
      )
      .sort((a: Negotiation, b: Negotiation) => {
        const perDate = formatAndCompareDate(b.ONE_DATA, a.ONE_DATA);
        return perDate !== 0 ? perDate : +b.ORC_CODIGO - +a.ORC_CODIGO;
      });
    setNegotiations(orcamentos);

    setCardRecusado(
      payload.orcamentos.reduce((accumulator: number, currentValue: Negotiation) => {
        if (currentValue.ONE_STATUS === NegotiationSituation.Recusado) return accumulator + 1;
        else return accumulator;
      }, 0)
    );
    setCardNegociando(
      payload.orcamentos.reduce((accumulator: number, currentValue: Negotiation) => {
        if (currentValue.ONE_STATUS === NegotiationSituation.Negociando) return accumulator + 1;
        else return accumulator;
      }, 0)
    );
    setCardFechado(
      payload.orcamentos
        .reduce((accumulator: number, currentValue: Negotiation) => {
          if (currentValue.ONE_STATUS === NegotiationSituation.Fechado)
            return accumulator + +correctNumberWithComma(currentValue.ONE_VALOR);
          else return accumulator;
        }, 0)
        .toFixed(2)
    );

    setIsLoading(false);
  };

  const fetchOfferDetails = async (negotiation: Negotiation) => {
    setIsLoadingModal(true);
    setOffer({} as Offer);
    openModal('detalhesOferta');

    const { payload } = await getOfferDetails(negotiation.OFE_CODIGO);

    setOffer(payload.oferta);
    setIsLoadingModal(false);
  };

  const fetchNegotiationHistory = async (orc_codigo: string) => {
    setIsLoadingModal(true);
    openModal('verHistorico');

    const { payload } = await getNegotiationHistory(orc_codigo);
    setHistory(payload.historico);
    setIsLoadingModal(false);
  };

  const handleExportAssociateStatement = async (params: FilterExportAssociateStatement) => {
    const param = {
      emp_codigo: params.emp_codigo,
      disableFilterDate: params.disableFilterDate,
      dateStart: params.dateStart?.split('-').reverse().join('_') || 'x',
      dateEnd: params.dateEnd?.split('-').reverse().join('_') || 'x',
      status: params.status,
    };
    if (params.searchAll) {
      exportAssociatesStatementToXLS({
        emp_codigo: empresa.EMP_CODIGO,
        disableFilterDate: params.disableFilterDate,
        dateStart: params.dateStart?.split('-').reverse().join('_') || 'x',
        dateEnd: params.dateEnd?.split('-').reverse().join('_') || 'x',
        status: params.status,
      });
    } else if (params.type === 'PDF') exportAssociateStatementToPDF(param);
    else if (params.type === 'XLS') {
      exportAssociateStatementToXLS({ ...param, status: params.status });
    }
  };

  return (
    <div className="wrapper">
      <Menu />

      <div className="main-panel">
        <Header title="Negociações" />

        <div className="content">
          {isLoading && <PageLoading />}
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <IndicatorCard
                  label="Recusado"
                  value={cardRecusado}
                  title="Negociações recusadas"
                  icon="nc-icon nc-icon nc-bell-55 text-danger"
                />
              </div>
              <div className="col-lg-4 col-sm-6">
                <IndicatorCard
                  label="Negociando"
                  value={cardNegociando}
                  title="Negociações em andamento"
                  icon="nc-icon nc-icon nc-bell-55 text-warning"
                />
              </div>
              <div className="col-lg-4 col-sm-12">
                <IndicatorCard
                  label="Fechado SC$"
                  value={formatNumberWithoutPrefix(+cardFechado)}
                  title="Negociações fechadas"
                  icon="nc-icon nc-icon nc-bell-55 text-success"
                />
              </div>
            </div>

            <ModalFilter
              filtersState={{ filters: filters, setFilters: setFilters }}
              fetchMyNegotiations={fetchAssociateNegotiations}
            />
            <ModalOfferDetails offer={offer} isLoading={isLoadingModal} />
            <ModalNegotiationHistory isLoading={isLoadingModal} history={history} />

            <div className="row">
              <div className="col-md-12">
                <div className="card table-with-links">
                  <CardHeader
                    title="Negociações dos meus associados"
                    subtitle="Aplique os filtros para alterar o extrato"
                    filterAction={() => openModal('filtro')}
                    legends={[
                      { iconClass: 'fa fa-list', label: 'ver histórico' },
                      { iconClass: 'fa fa-print', label: 'imprimir voucher' },
                    ]}
                    actions={[
                      {
                        iconClass: 'fas fa-file-export',
                        variant: 'btn-secondary',
                        label: ' Exportar extrato do associado',
                        action: () => openModal('ModalExportAssociateStatement'),
                      },
                    ]}
                  />

                  <ExportAssociateStatement onSubmit={handleExportAssociateStatement} />

                  <div className="card-body">
                    <TableWithLinks
                      isLoading={isLoading}
                      emptyTableMessage="Nenhuma negociação encontrada. Tente alterar os filtros."
                      onClickingRow={(negotiation) => fetchOfferDetails(negotiation)}
                      columns={[
                        { field: 'ORC_CODIGO', label: 'ORÇAM', width: '70px', alignment: 'text-center' },
                        { field: 'solicitanteFormatted', label: 'Comprador', alignment: 'text-center', width: '200px' },
                        { field: 'destinatarioFormatted', label: 'Vendedor', alignment: 'text-center', width: '200px' },
                        { field: 'OFE_TITULO', label: 'Oferta', alignment: 'text-center' },
                        { field: 'orcTipoFormatted', label: 'Tipo', alignment: 'text-left', width: '45px' },
                        { field: 'ONE_DATA', label: 'Atualização', alignment: 'text-center', width: '100px' },
                        { field: 'oneValorFormatted', label: 'Valor', alignment: 'text-center', width: '90px' },
                        { field: 'oneStatusFormatted', label: 'Situação', alignment: 'text-center', width: '150px' },
                        {
                          field: 'commissionStatusFormatted',
                          label: 'Comissão',
                          alignment: 'text-center',
                          width: '90px',
                        },
                      ]}
                      data={negotiations.map((negotiation) => {
                        const commissionStatus =
                          negotiation.ORC_TIPO == NegotiationType.VENDA
                            ? negotiation.TRA_CODSTTPEDSITVEN.split('-')[3]
                            : negotiation.TRA_CODSTTPEDSITCOM.split('-')[3];

                        return {
                          ...negotiation,
                          solicitanteFormatted: (
                            <>
                              <i className="fas fa-shopping-bag" style={{ color: 'blue' }} /> {negotiation.SOLICITANTE}
                            </>
                          ),
                          destinatarioFormatted: (
                            <>
                              <i className="fas fa-store-alt" style={{ color: 'blueviolet' }} />{' '}
                              {negotiation.DESTINATARIO}
                            </>
                          ),
                          orcTipoFormatted: (
                            <span
                              className={`highlight-letter-badge ${
                                negotiation.ORC_TIPO === NegotiationType.COMPRA
                                  ? 'highlight-letter-badge-red'
                                  : negotiation.ORC_TIPO === NegotiationType.VENDA && 'highlight-letter-badge-green'
                              }`}
                            >
                              {negotiation.ORC_TIPO[0]}
                            </span>
                          ),
                          oneValorFormatted:
                            negotiation.ONE_VALOR === '0' ? '(N/I)' : formatNumberWithoutPrefix(+negotiation.ONE_VALOR),
                          oneStatusFormatted: (
                            <>
                              {negotiation.ONE_STATUS === NegotiationSituation.Fechado && (
                                <>
                                  <i className="fas fa-check-double" /> Fechado
                                </>
                              )}
                              {negotiation.ONE_STATUS === NegotiationSituation.Negociando && (
                                <>
                                  <i className="fas fa-check" /> Em Negociação
                                </>
                              )}
                              {negotiation.ONE_STATUS === NegotiationSituation.Recusado && (
                                <>
                                  <i className="fas fa-times" /> Recusado
                                </>
                              )}
                            </>
                          ),
                          commissionStatusFormatted: (
                            <span style={{ color: commissionStatus ? 'green' : 'red', fontWeight: 'bold' }}>
                              {commissionStatus ? 'Paga' : 'Pendente'}
                            </span>
                          ),
                        };
                      })}
                      actions={[
                        {
                          iconClass: 'fa fa-list clickable',
                          title: 'ver histórico',
                          action: (negotiation) => fetchNegotiationHistory(negotiation.ORC_CODIGO),
                        },
                        {
                          iconClass: 'fa fa-print clickable',
                          title: 'imprimir voucher',
                          action: (negotiation) =>
                            printVoucher({ orc_codigo: negotiation.ORC_CODIGO, ofe_codigo: negotiation.OFE_CODIGO }),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Negociacoes;
