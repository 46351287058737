import React, { useContext, useRef } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { formatNumberToVPS } from '~/utils/util';
import { HeaderProps } from '~/types';
import { Helmet } from 'react-helmet';
import LoadingFormSpin from '../loading/FormSpin';
import { EmpType } from '~/enum';
import Select, { components } from 'react-select';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

const defaultProps: HeaderProps = {
  title: '',
  showBalance: false,
  hideNavItens: false,
};

const Header: React.FunctionComponent<HeaderProps> = (props) => {
  const { signOut, company: empresa, balance, user, setCompany } = useContext(AuthContext);

  const buttonNavbarMobileSearch = useRef<HTMLButtonElement>(null);

  const logoff = () => {
    signOut();
  };

  const changeCompany = (companyId: number) => {
    const company = user.EMPRESAS?.find(({ EMP_CODIGO }) => EMP_CODIGO == companyId);
    if (company) {
      setCompany({ ...company, EMP_TIPO: empresa.EMP_TIPO });
      location.reload();
    }
  };

  return (
    <>
      <Helmet
        title={`${props.titleTag ? props.titleTag : typeof props.title === 'string' ? props.title : ''} - Socicoin`}
      />
      <nav className="navbar navbar-expand-lg" style={{ maxHeight: '91px', position: 'sticky', top: 0, zIndex: 4 }}>
        <div className="container-fluid p-0">
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              {!props.hideNavItens ? (
                <button id="minimizeSidebar" className="btn btn-warning btn-fill btn-round btn-icon d-none d-lg-block">
                  <i className="fa fa-ellipsis-v visible-on-sidebar-regular text-default"></i>
                  <i className="fa fa-navicon visible-on-sidebar-mini text-default"></i>
                </button>
              ) : (
                <div style={{ width: '38px' }} />
              )}
            </div>
            <a className="navbar-brand" href="#" style={{ pointerEvents: 'none', lineHeight: 1.1 }}>
              <span className="d-block">{props.title}</span>

              {props.showBalance && (
                <div className="d-inline-block ml-1 small">
                  Saldo:{' '}
                  {balance === null ? (
                    <LoadingFormSpin
                      style={{ display: 'inline-block', marginLeft: '10px', marginBottom: '3px', marginRight: '20px' }}
                    />
                  ) : (
                    formatNumberToVPS(+balance)
                  )}
                </div>
              )}
            </a>
          </div>
          <div className="d-none nav-mobile-block">
            {props.searchBoxState !== undefined && (
              <OverlayTrigger
                trigger={['click']}
                placement="left"
                overlay={
                  <Popover id="popover-basic" className="navbar-mobile-search-popover">
                    <div className="d-flex" style={{ height: '45px', alignItems: 'center' }}>
                      <form
                        className="navbar-form navbar-left navbar-search-form w-100 h-100"
                        role="search"
                        onSubmit={(e) => e.preventDefault()}
                      >
                        <div className="input-group h-100" style={{ alignItems: 'center' }}>
                          <i
                            className="nc-icon nc-zoom-split nav-item-white mx-1"
                            style={{ color: 'grey', fontWeight: 'bold' }}
                          />
                          <input
                            type="text"
                            value={props.searchBoxState.searchBoxValue}
                            onChange={(e) =>
                              props.searchBoxState !== undefined &&
                              props.searchBoxState.setSearchBoxValue(e.target.value)
                            }
                            className="form-control nav-item-white h-100"
                            placeholder="Pesquisar na página"
                          />
                        </div>
                      </form>
                      <button
                        className="btn-raw"
                        style={{ fontSize: '.8rem' }}
                        onClick={() => buttonNavbarMobileSearch.current?.click()}
                      >
                        <i className="fas fa-times" />
                      </button>
                    </div>
                  </Popover>
                }
              >
                <button className="navbar-mobile-search" type="button" ref={buttonNavbarMobileSearch}>
                  <i className="nc-icon nc-zoom-split" />
                </button>
              </OverlayTrigger>
            )}

            <button
              className="navbar-toggler navbar-toggler-right"
              type="button"
              data-toggle="collapse"
              aria-controls="navigation-index"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-bar burger-lines"></span>
              <span className="navbar-toggler-bar burger-lines"></span>
              <span className="navbar-toggler-bar burger-lines"></span>
            </button>
          </div>
          <div className="collapse navbar-collapse justify-content-end">
            {props.headerAppend && (
              <ul className="nav navbar-nav">
                <div className="nav-mobile-hide">{props.headerAppend}</div>
              </ul>
            )}

            {props.searchBoxState !== undefined && (
              <ul className="nav navbar-nav mr-auto w-100">
                <form
                  className="navbar-form navbar-left navbar-search-form w-100"
                  role="search"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="input-group blink-search-box">
                    <i className="nc-icon nc-zoom-split nav-item-white" />
                    <input
                      type="text"
                      value={props.searchBoxState.searchBoxValue}
                      onChange={(e) =>
                        props.searchBoxState !== undefined && props.searchBoxState.setSearchBoxValue(e.target.value)
                      }
                      className="form-control nav-item-white"
                      placeholder="Pesquisar na página"
                    />
                  </div>
                </form>
              </ul>
            )}
            {empresa?.EMP_TIPO === EmpType.MASTER_FRANQUIA && (
              <ul className="nav navbar-nav">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    color: '#888888',
                    minWidth: '250px',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Select
                    styles={{
                      container: (props) => ({ ...props, width: '250px' }),
                      control: (style, props) => ({
                        ...style,
                        border: 'var(--color-default) 1px solid',
                        borderColor: 'var(--color-default) !important',
                        boxShadow: props.isFocused ? '0 0 0 1px orange' : undefined,
                      }),
                    }}
                    defaultValue={{
                      value: empresa.EMP_CODIGO,
                      label: empresa.EMP_NOMEFANTASIA,
                      labelAux: empresa.EMP_RAZAOSOCIAL,
                    }}
                    options={
                      user.EMPRESAS?.map((company) => ({
                        value: company.EMP_CODIGO,
                        label: company.EMP_NOMEFANTASIA,
                        labelAux: company.EMP_RAZAOSOCIAL,
                      })) || []
                    }
                    onChange={(selected) => selected && changeCompany(selected.value)}
                    getOptionLabel={(option) => option.label}
                    components={{
                      Option: (props) => (
                        <components.Option {...props}>
                          <div className="d-flex flex-column text-dark">
                            <span>{props.label}</span>
                            <small>{props.data.labelAux}</small>
                          </div>
                        </components.Option>
                      ),
                    }}
                  />
                </div>
              </ul>
            )}
            {props.showBalance && empresa.EMP_TIPO === EmpType.ASSOCIADO && (
              <ul className="nav navbar-nav">
                <div
                  className="d-flex nav-mobile-hide"
                  style={{
                    flexDirection: 'column',
                    color: '#888888',
                    minWidth: '180px',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <small>Vencimento: {empresa.EMP_DATACADASTRO.slice(0, 5)}</small>
                  <small>Segmento: {empresa.SEG_DESCRICAO}</small>
                </div>
              </ul>
            )}
            {!props.hideNavItens && (
              <ul className="navbar-nav">
                {/* <li className="dropdown nav-item">
                <a href="#" className="nav-link">
                  <i className="nc-icon nc-bell-55"></i>
                  <span className="notification">5</span>
                  <span className="d-lg-none">&nbsp;Novas Ofertas</span>
                </a>
              </li> */}
                <a href="/" className="nav-item" onClick={logoff}>
                  <span className="nav-link">
                    <span className="d-lg-none">Sair</span>
                    <i className="nc-icon nc-button-power"></i>
                  </span>
                </a>
              </ul>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

Header.defaultProps = defaultProps;

export default Header;
