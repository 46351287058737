import React, { InputHTMLAttributes, useRef, useState } from 'react';
import { ControlledMoneyFormatterProps } from '~/types';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { formatNumberToMoney, formatNumberToVPS, formatNumberWithoutPrefix } from '~/utils/util';
import LoadingFormSpin from '~/components/loading/FormSpin';

/**
 * @deprecated
 * Opte por utilizar o componente MaskMoneyControlled
 */
const ControlledMoneyFormatter: React.FunctionComponent<ControlledMoneyFormatterProps> = ({
  label,
  hasError,
  isRequired,
  name,
  keyComponent,
  control,
  defaultValue,
  readOnly,
  prefix,
  isLoading,
  placeholder,
  isDisabled,
  onBlur,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={`form-group form-loader ${hasError === undefined ? '' : hasError ? 'has-error' : 'has-success'}`}>
      <label>
        {label}
        {isRequired && <span className="star" />}
      </label>
      <Controller
        key={keyComponent}
        name={name}
        control={control}
        defaultValue={defaultValue}
        onFocus={() => {
          inputRef.current?.focus();
        }}
        render={({ onChange, value }) => (
          <NumberFormat
            getInputRef={inputRef}
            className="form-control"
            displayType={'input'}
            spellCheck="false"
            placeholder={placeholder}
            readOnly={readOnly}
            disabled={isDisabled}
            format={(e) =>
              prefix === 'SC$'
                ? formatNumberToVPS(+e / 100)
                : prefix === 'R$'
                ? formatNumberToMoney(+e / 100)
                : formatNumberWithoutPrefix(+e / 100)
            }
            defaultValue={value * 100}
            value={value * 100}
            onValueChange={(e) => onChange(e.floatValue ? e.floatValue / 100 : undefined)}
            onBlur={(e: any) => onBlur && onBlur(e, value)}
          />
        )}
      />
      {isLoading && <LoadingFormSpin />}
    </div>
  );
};

export default ControlledMoneyFormatter;
