import React, { ReactNode, useContext, useState } from 'react'
import { Redirect, Route } from 'react-router'
import { AuthContext } from '../context/AuthContext'
import { EmpType } from '~/enum'

interface PrivateRoutes {
    children: ReactNode;
    allowedUser: Array<EmpType>;
    path: string
}

const PrivateRoutes: React.FunctionComponent<PrivateRoutes> = (props) => {
    const { isAuthenticated, company: empresa } = useContext(AuthContext)

    return (
        <Route
            path={props.path}
            render={() => {
                if (!isAuthenticated())
                    return <Redirect to="/" />

                if (props.allowedUser.some(u => u === empresa.EMP_TIPO))
                    return props.children
                else
                    return <Redirect to="/nao-autorizado" />
            }}
        />
    )
}

export default PrivateRoutes