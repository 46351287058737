import React, { createContext, useCallback, useState } from 'react';
import api from '~/config/api';
import { EmpType } from '~/enum';
import { getBalance } from '~/service/DashboardService';

const VIA_PERMUTA_USER = '@viaPermuta:usuario';
const VIA_PERMUTA_COMPANY = '@viaPermuta:empresa';
const VIA_PERMUTA_BALANCE = '@viaPermuta:balance';
const VIA_PERMUTA_HAS_ACCESS = '@viaPermuta:hasAccessFinance';

export interface Usuario {
  USU_CODIGO: number;
  USU_NOME: string;
  USU_EMAIL: string;

  EMP_CODIGO: string;
  GRU_CODIGO: string;
  ITI_CODIGO: string;
  PER_CODCOPIA: string;
  PER_CODIGO: string;
  PER_COMENTPER: string;
  PER_COMENTVAL: string;
  PER_COMREMPER: string;
  PER_COMREMVAL: string;
  PER_COMRENPER: string;
  PER_COMRENVAL: string;
  PER_DESCONTINUADO: string;
  PER_DESCRICAO: string;
  UEM_PADRAO: string;
  USU_CODSUPERIOR: string;
  USU_CPF: string;
  USU_DATAANIVER: string;
  USU_DATAONLINE: string;
  USU_LOGIN: string;
  USU_ONLINE: string;
  USU_OS: string;
  USU_SENHA: string;
  USU_SENHAAUX: string;
  USU_SEXO: string;
  USU_TELEFONE: string;
  USU_TOKEN: string;
  USU_TRATAMENTO: string;
  EMPRESAS: null | Empresa[];
}

export interface Empresa {
  EMP_CODIGO: number;
  EMP_CPFCNPJ: string;
  EMP_RAZAOSOCIAL: string;
  EMP_NOMEFANTASIA: string;
  EMP_TIPO: string;
  EMP_CODMATRIZ: number;
  SEG_CODIGO: number;
  SEG_DESCRICAO: string;

  CFO_CODIGO: string;
  CNE_CODIGO: string;
  ECC_CODASSOCIADO: string;
  ECC_CODENTRANTES: string;
  ECC_CODREMUNERACAO: string;
  ECC_CODRENOVACAO: string;
  ECC_CODROYALTY: string;
  ECC_CODROYALTYFRANQ: string;
  EMP_ALERTANEGOCIO: string;
  EMP_ANUIDADE: string;
  EMP_CHAVESHOPLINE: string;
  EMP_CNAEMUNICIPAL: string;
  EMP_CODEMPRESASHOPLINE: string;
  EMP_CODIGOFRANQUIA: string;
  EMP_COMISSAOASSCREDPER: string;
  EMP_COMISSAOASSCREDPERVPMIX: string;
  EMP_COMISSAOASSCREDVAL: string;
  EMP_COMISSAOASSCREDVALVPMIX: string;
  EMP_COMISSAOASSDEBPER: string;
  EMP_COMISSAOASSDEBPERVPMIX: string;
  EMP_COMISSAOASSDEBVAL: string;
  EMP_COMISSAOASSDEBVALVPMIX: string;
  EMP_COMISSAOSEGCREDPER: string;
  EMP_COMISSAOSEGCREDVAL: string;
  EMP_COMISSAOSEGDEBPER: string;
  EMP_COMISSAOSEGDEBVAL: string;
  EMP_CONTATOADMIN: string;
  EMP_CONTATOFINANCEIRO: string;
  EMP_COPIADRE: string;
  EMP_COPIAFORMAPAGTO: string;
  EMP_COPIAMODULO: string;
  EMP_COPIAPERFIL: string;
  EMP_COPIAPLANOCONTA: string;
  EMP_COPIAPRODUTO: string;
  EMP_COPIAWCNT: string;
  EMP_COPIAWOS: string;
  EMP_COPIAWPROPOSTA: string;
  EMP_COPIAWVENDA: string;
  EMP_DATAADESAOSIMPLESNACIONAL: string;
  EMP_DATAALTERACAOSTATUSVPMIX: string;
  EMP_DATAATIVACAOVPMIX: string;
  EMP_DATACADASTRO: string;
  EMP_DATAENVIARBOLETO: string;
  EMP_DESCRITIVO: string;
  EMP_EMAILADMIN: string;
  EMP_EMAILFINANCEIRO: string;
  EMP_ENVIARBOLETO: string;
  EMP_FECHAMENTO: string;
  EMP_FRANQUEADORA: string;
  EMP_FRANQUIA: string;
  EMP_FUNDOGARANTIDOR: string;
  EMP_GRUPO: string;
  EMP_HOMEPAGE: string;
  EMP_IESUBISTITUTOTRIBUTARIO: string;
  EMP_INCENTIVADORCULTURAL: string;
  EMP_INSCRICAOESTADUAL: string;
  EMP_INSCRICAOMUNICIPAL: string;
  EMP_LIBERAROFERTA: string;
  EMP_LOGOTIPO: string;
  EMP_OPTANTESIMPLESNACIONAL: string;
  EMP_PEDIDOSHOPLINE: string;
  EMP_PERCMAXREAISOFERTAVPMIX: string;
  EMP_PERMITIRVENDASEMCONTRATO: string;
  EMP_QTDMINIMAOFERTAVPMIX: string;
  EMP_REGIMEESPECIALTRIB: string;
  EMP_RESPOSTA: string;
  EMP_ROYALTYPER: string;
  EMP_ROYALTYVAL: string;
  EMP_STATUSVPMIX: 'Ativo' | 'Inativo' | '';
  EMP_SUBGRUPO: string;
  EMP_TAXAMKTPER: string;
  EMP_TAXAMKTVAL: string;
  EMP_TAXAVPMIX: string;
  EMP_TELEFONEADMIN: string;
  EMP_TELEFONEFINANCEIRO: string;
  EMP_VP: string;
  ITI_CODIGO: string;
  RTB_CODIGO: string;
  EMP_ASAAS_ATIVO: string;
  EMP_ASAAS_CPFCNPJ: string;
  EMP_ASAAS_TOKEN: string;
  EMP_ASAAS_SENHA: string;
  EMP_ASAAS_USERID: string;
  EMP_ASAAS_SPLIT_ATIVO: string;
  EMP_ASAAS_SPLIT_PERC: string;
  EMP_ASAAS_SPLIT_VALOR: string;
  EMP_ASAAS_WALLETID_MATRIZ: string;
  matriz?: Empresa;
  EMP_FRANQUIA_ASAAS_ATIVO: string;
  FRANQUIAS: null | Empresa[];
}

interface SignInCredentials {
  usu_login: string;
  usu_senha: string;
}

interface SignIn {
  status: boolean;
  emp_tipo: string | null;
  mensagem?: string;
}

type ChangeBalance = (balance: number) => void;

interface AuthContextState {
  user: Usuario;
  company: Empresa;
  balance: number | null;
  signIn(credentials: SignInCredentials): Promise<SignIn>;
  signOut(): void;
  isAuthenticated(): boolean;
  changeBalance: ChangeBalance;
  setCompany: (company: Empresa) => void;
}

interface AuthState {
  user: Usuario;
  balance: number | null;
  company: Empresa;
}

const AuthContext = createContext<AuthContextState>({} as AuthContextState);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const usuario = localStorage.getItem(VIA_PERMUTA_USER);
    const empresa = localStorage.getItem(VIA_PERMUTA_COMPANY);
    const balance = localStorage.getItem(VIA_PERMUTA_BALANCE);

    if (usuario && empresa) {
      return {
        user: JSON.parse(usuario),
        company: JSON.parse(empresa),
        balance: balance === 'null' ? null : Number(balance),
      };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ usu_login, usu_senha }: SignInCredentials) => {
    const response = await api.post('/login/autenticar-v2', {
      usu_login,
      usu_senha,
    });

    if (response.data.type === 'erro')
      return {
        status: false,
        emp_tipo: null,
        mensagem: response.data.mensagem,
      };

    delete response.data.usuario.USU_SENHA;
    delete response.data.usuario.USU_SENHAAUX;
    delete response.data.usuario.USU_TOKEN;
    delete response.data.usuario.USU_OS;

    const empresaAux = {
      ...response.data.empresa,
      EMP_TIPO: response.data.usuario.EMPRESAS
        ? EmpType.MASTER_FRANQUIA
        : response.data.empresa.EMP_CODIGO === response.data.empresa.EMP_CODMATRIZ
        ? EmpType.FRANQUEADORA
        : response.data.empresa.EMP_TIPO,
    };

    localStorage.setItem(VIA_PERMUTA_USER, JSON.stringify({ ...response.data.usuario }));
    localStorage.setItem(VIA_PERMUTA_COMPANY, JSON.stringify(empresaAux));
    localStorage.setItem(VIA_PERMUTA_BALANCE, 'null');

    setData({
      user: response.data.usuario,
      company: empresaAux,
      balance: null,
    });

    syncBalanceWithRetry({ ...response.data.empresa, usu_login });

    return {
      status: true,
      emp_tipo: empresaAux.EMP_TIPO,
    };
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem(VIA_PERMUTA_COMPANY);
    localStorage.removeItem(VIA_PERMUTA_USER);
    localStorage.removeItem(VIA_PERMUTA_HAS_ACCESS);
    localStorage.removeItem(VIA_PERMUTA_BALANCE);

    setData({} as AuthState);
  }, []);

  const isAuthenticated = useCallback(() => {
    if (localStorage.getItem(VIA_PERMUTA_USER) && localStorage.getItem(VIA_PERMUTA_COMPANY)) return true;
    else return false;
  }, []);

  const changeBalance: ChangeBalance = (balance: number) => {
    localStorage.setItem(VIA_PERMUTA_BALANCE, balance.toString());
    setData((data) => ({ ...data, balance: balance }));
  };

  const syncBalanceWithRetry = async (empresa: Record<string, any>) => {
    const result = await syncBalance(empresa);

    if (!result) await syncBalance(empresa);
  };

  const syncBalance = async (empresa: Record<string, any>): Promise<boolean> => {
    const { success, payload } =
      ['associado', 'franquia'].includes(empresa.usu_login) || empresa.EMP_TIPO === 'F'
        ? 0
        : await getBalance(empresa.EMP_CODIGO);

    if (success) {
      changeBalance(+payload.balance);
      return true;
    } else {
      return false;
    }
  };

  const setCompany = (company: Empresa) => {
    localStorage.setItem(VIA_PERMUTA_COMPANY, JSON.stringify(company));
    setData((current) => ({ ...current, company: company }));
  };

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        company: data.company,
        balance: data.balance,
        signIn,
        signOut,
        isAuthenticated,
        changeBalance,
        setCompany,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
