import api from '~/config/api';
import { baseURL } from '~/config/api';
import { downloadDocuments } from '~/utils/util';

export const getMyVouchers = async ({ emp_codigo, data_inicial, data_final, vou_status }) => {
    try {
        const { data } = await api.post('/api/vouchers/carregar-todos', {
            emp_codigo,
            data_inicial,
            data_final,
            vou_status,
        })

        return data

    } catch (error) {
        return error
    }
}

export const baixarVoucher = async ({ vou_nomeutilizador, vou_rgutilizador, vou_obsutilizador, vou_codigo }) => {
    try {
        const { data } = await api.post('/api/vouchers/salvar', {
            vou_nomeutilizador,
            vou_rgutilizador,
            vou_obsutilizador,
            vou_codigo,
        })

        return data
    } catch (error) {
        return error
    }
}

export const printVoucher = ({ orc_codigo, ofe_codigo }) => {
  const url = `${baseURL}/api/vouchers/imprimir-voucher/${orc_codigo}/${ofe_codigo}`;
  downloadDocuments(url, `Voucher.pdf`, true)
};